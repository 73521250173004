import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { cloneDeep } from 'lodash'

/** @todo Fix import cycle */
// eslint-disable-next-line import/no-cycle
import { store } from '../stores/RootStore'
import { convertIncomingData, convertOutgoingData } from './transforms'

// Default config options
const DEFAULT_OPTIONS = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
  withCredentials: true, // Required for SSO
}

// Create instance
const instance = axios.create(DEFAULT_OPTIONS)

// Initialize authentication interceptor to automatically attempt to refresh
// an expired token
const triggerRefresh = (failedRequest) => {
  if (store) {
    return store.triggerRefreshToken(failedRequest)
  }

  // eslint-disable-next-line no-console
  console.debug('`store` is not defined, cannot refresh expired token.')
  return null
}
createAuthRefreshInterceptor(instance, triggerRefresh, {
  statusCodes: [401], // Updated BE triggers 401 error rather than a 403
  // If the request that returned a 401 error does include a `code` of `token_not_valid`, this is not
  // an access token issue and should be ignored
  shouldRefresh: (error) =>
    error.response.data.code === 'token_not_valid' && error.response.data.messages,
})

instance.interceptors.request.use((config) => {
  const _config = cloneDeep(config)

  // Set the AUTH token for any request
  // If we have a user token, update the config
  if (store.user.accessToken && store.user.accessToken !== '') {
    _config.headers.Authorization = `Bearer ${store.user.accessToken}`
  }

  // Bypass the conversion if the data is FormData
  if (config.data instanceof FormData) {
    delete _config.headers['Content-Type']
    // If it's FormData, just return the config as it is
    return _config
  }

  // Check if `config.data` is a string, if so we need to parse it to an object in order to properly convert the data
  if (typeof config.data === 'string') {
    _config.data = convertOutgoingData(JSON.parse(config.data))
  }
  // Otherwise, convert the data normally
  else {
    _config.data = convertOutgoingData(config.data)
  }

  return _config
})

/**
 * - If a response is successful, only return the data portion of the response
 */
instance.interceptors.response.use(
  (response) => {
    if (response.status === 204 || response.status === 205) {
      return null
    }

    /**
     * Somewhere along the lines, there's a bug in either our code, axios, or axios-auth-refresh
     * that causes this interceptor to get called twice only after re-calling a failed request that
     * has been given a fresh access token via the authRefreshInterceptor
     *
     * If you print out the response here, you'll see that this interceptor hits twice after the auth
     * refresh - once with the expected shape, e.g. { data: { new_key: 'info' } }, and a second time
     * with the object that has already been transformed, e.g. { newKey: 'info' }
     *
     * In order to avoid assumptions about the existance of the `data` key, the simple workaround
     * here is to first attempt to use `response.data` and fallback to `response` if needed. This
     * ensures that all data is transformed even if a `data` key does not exist, though it does
     * attempt to transform the data twice in the case described above.
     */
    return convertIncomingData(response.data || response, true)
  },
  (error) => {
    // Handles catching errors when the authenticated user is marked as inactive
    // Clear the store to log the user out
    if (error.response.data.code === 'user_inactive' && store) store.clearStore()
    else throw convertIncomingData(error)
  },
)

export default instance
