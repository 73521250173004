import React from 'react'
import PropTypes from 'prop-types'

const ActionMemoCheckIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 14.6667H9.33333C8.59667 14.6667 8 14.07 8 13.3333V9.33333C8 8.59667 8.59667 8 9.33333 8H13.3333C14.07 8 14.6667 8.59667 14.6667 9.33333V13.3333C14.6667 14.07 14.07 14.6667 13.3333 14.6667Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99725 10.826C7.57059 10.926 7.12659 10.9866 6.66659 10.9866C6.28725 10.9866 5.91725 10.9493 5.55925 10.8806L3.46659 12.625C3.46659 11.9117 3.46525 10.5186 3.46592 10.0146C2.17459 9.13598 1.33325 7.74265 1.33325 6.15998C1.33325 3.48198 3.73192 1.33331 6.66659 1.33331C9.60125 1.33331 11.9999 3.48198 11.9999 6.15998C11.9999 6.81398 11.8546 7.43531 11.5959 8.00265"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8147 10.5927L10.9627 12.4447L9.85205 11.3333"
      stroke="inherit"
      strokeWidth="1.5294"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionMemoCheckIcon.defaultProps = {
  className: '',
}

ActionMemoCheckIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionMemoCheckIcon
