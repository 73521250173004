import React from 'react'
import PropTypes from 'prop-types'

const CommentsIcon = ({ className, unread }) =>
  unread ? (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84836 4.84522C7.18302 4.50195 9.57113 4.32422 12.0003 4.32422C14.4292 4.32422 16.8171 4.50192 19.1516 4.84513C21.1299 5.13599 22.5 6.86867 22.5 8.81478V14.8337C22.5 16.7798 21.1299 18.5125 19.1516 18.8033C17.2123 19.0884 15.2361 19.2593 13.2302 19.309C13.1266 19.3116 13.0318 19.3531 12.9638 19.4211L8.78033 23.6045C8.56583 23.819 8.24324 23.8832 7.96299 23.7671C7.68273 23.651 7.5 23.3776 7.5 23.0742V19.1192C6.60901 19.0376 5.72491 18.9321 4.84836 18.8032C2.87004 18.5123 1.5 16.7797 1.5 14.8336V8.81486C1.5 6.86877 2.87004 5.1361 4.84836 4.84522Z"
        fill="inherit"
      />
      <circle cx="20" cy="6.07422" r="5" fill="#FF1A25" stroke="#FDFDF9" strokeWidth="2" />
    </svg>
  ) : (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84836 0.771003C6.18302 0.427728 8.57113 0.25 11.0003 0.25C13.4292 0.25 15.8171 0.427696 18.1516 0.770912C20.1299 1.06177 21.5 2.79445 21.5 4.74056V10.7595C21.5 12.7056 20.1299 14.4382 18.1516 14.7291C16.2123 15.0142 14.2361 15.1851 12.2302 15.2348C12.1266 15.2374 12.0318 15.2788 11.9638 15.3468L7.78033 19.5303C7.56583 19.7448 7.24324 19.809 6.96299 19.6929C6.68273 19.5768 6.5 19.3033 6.5 19V15.045C5.60901 14.9634 4.72491 14.8579 3.84836 14.729C1.87004 14.4381 0.5 12.7054 0.5 10.7593V4.74064C0.5 2.79455 1.87004 1.06188 3.84836 0.771003Z"
        fill="inherit"
      />
    </svg>
  )

CommentsIcon.defaultProps = {
  className: '',
  unread: false,
}

CommentsIcon.propTypes = {
  className: PropTypes.string,
  unread: PropTypes.bool,
}

export default CommentsIcon
