import React from 'react'
import PropTypes from 'prop-types'

const ActionPaperCheckIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2777 4.27445L10.3916 2.38833C10.1415 2.13816 9.80225 1.9975 9.44851 1.9975H4.66519C3.9285 1.9975 3.3313 2.5947 3.3313 3.33139V12.6686C3.3313 13.4053 3.9285 14.0025 4.66519 14.0025H11.3346C12.0713 14.0025 12.6685 13.4053 12.6685 12.6686V5.21751C12.6685 4.86377 12.5279 4.52454 12.2777 4.27445Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3176 5.83333H10C9.44772 5.83333 9 5.38562 9 4.83333V2.51575"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33379 8.6665L7.66643 10.3339L6.66602 9.33345"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionPaperCheckIcon.defaultProps = {
  className: '',
}

ActionPaperCheckIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionPaperCheckIcon
