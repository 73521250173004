import React from 'react'
import PropTypes from 'prop-types'

const DoubleChevronOpenIcon = ({ className }) => (
  <svg
    width="15"
    height="23"
    viewBox="0 0 15 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.01914 17.9856L2.10889 22.6697C1.87806 22.8899 1.58429 23 1.22756 23C0.870835 23 0.577059 22.8899 0.346235 22.6697C0.115412 22.4495 0 22.1693 0 21.829C0 21.4887 0.115412 21.2084 0.346235 20.9883L5.22501 16.3342C5.70764 15.8738 6.30568 15.6436 7.01914 15.6436C7.73259 15.6436 8.33063 15.8738 8.81326 16.3342L13.692 20.9883C13.9229 21.2084 14.0383 21.4887 14.0383 21.829C14.0383 22.1693 13.9229 22.4495 13.692 22.6697C13.4612 22.8899 13.1674 23 12.8107 23C12.454 23 12.1602 22.8899 11.9294 22.6697L7.01914 17.9856ZM7.01914 5.01436L11.9294 0.330287C12.1602 0.110096 12.454 0 12.8107 0C13.1674 0 13.4612 0.110096 13.692 0.330287C13.9229 0.550479 14.0383 0.830722 14.0383 1.17102C14.0383 1.51131 13.9229 1.79156 13.692 2.01175L8.81326 6.6658C8.33063 7.1262 7.73259 7.3564 7.01914 7.3564C6.30568 7.3564 5.70764 7.1262 5.22501 6.6658L0.346235 2.01175C0.115412 1.79156 0 1.51131 0 1.17102C0 0.830722 0.115412 0.550479 0.346235 0.330287C0.577059 0.110096 0.870835 0 1.22756 0C1.58429 0 1.87806 0.110096 2.10889 0.330287L7.01914 5.01436Z"
      fill="inherit"
    />
  </svg>
)

DoubleChevronOpenIcon.defaultProps = {
  className: '',
}

DoubleChevronOpenIcon.propTypes = {
  className: PropTypes.string,
}

export default DoubleChevronOpenIcon
