import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import defaultOptions from '../Options'
import { getFormattedDate as formatDate } from '../Utils/date'

export const DatePickerContext = createContext({
  options: defaultOptions,
  value: null,
  view: 'days',
  setView: () => {},
  show: false,
  setShow: () => {},
  selectedDate: new Date(),
  changeSelectedDate: () => {},
  showSelectedDate: true,
  setShowSelectedDate: () => {},
  selectedMonth: 0,
  selectedYear: 0,
  getFormattedDate: () => '',
})

const DatePickerProvider = ({
  value,
  children,
  options: customOptions,
  onChange,
  show,
  setShow,
  selectedDateState,
}) => {
  const options = { ...defaultOptions, ...customOptions }
  const [view, setView] = useState('days')
  const [selectedDate, setSelectedDate] =
    selectedDateState || useState(options?.defaultDate || new Date())
  const [showSelectedDate, setShowSelectedDate] = useState(options?.defaultDate !== null)
  const selectedMonth = selectedDate.getMonth()
  const selectedYear = selectedDate.getFullYear()

  useEffect(() => {
    if (value) setSelectedDate(value)
  }, [value])

  const changeSelectedDate = (action, date) => {
    if (options?.maxDate && date > options.maxDate) return
    if (options?.minDate && date < options.minDate) return
    if (options?.disabledDates && options.disabledDates.indexOf(date) >= 0) return
    setSelectedDate(date)
    setShowSelectedDate(true)
    if (options?.autoHide && view === 'days' && action === 'date') setShow(false)
    if (onChange) onChange(date)
  }

  const getFormattedDate = (date, formatOptions) =>
    formatDate(options?.language ? options?.language : 'en', date, formatOptions)

  return (
    <DatePickerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        options,
        view,
        setView,
        show,
        setShow,
        selectedDate,
        changeSelectedDate,
        showSelectedDate,
        setShowSelectedDate,
        selectedMonth,
        selectedYear,
        getFormattedDate,
      }}
    >
      {children}
    </DatePickerContext.Provider>
  )
}

DatePickerProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node,
  options: PropTypes.array,
  onChange: PropTypes.func,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  selectedDateState: PropTypes.func,
}

export default DatePickerProvider
