import { createContext } from 'react'
import { action, observable } from 'mobx'
import _ from 'lodash'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  data: [
    {
      title: 'Fun Fact',
      description: 'Cuttlefish Have Eight Arms and Two Tentacles',
      new: true,
      loading: false,
    },
    {
      title: 'Fun Fact',
      description: 'Cuttlefish have not one, not two, but three hearts',
      new: false,
      loading: false,
    },
    {
      title: 'Fun Fact',
      description: 'Cuttlefish have truly impressive vision',
      new: false,
      loading: false,
    },
    {
      title: 'Fun Fact',
      description: 'There Are Over 100 Species of Cuttlefish',
      new: false,
      loading: false,
    },
    {
      title: 'Fun Fact',
      description: 'Cuttlefish Propel Themselves With Fins and Water',
      new: false,
      loading: true,
    },
    {
      title: 'Fun Fact',
      description: 'Cuttlefish Have a Very Short Lifespan',
      new: false,
      loading: true,
    },
    {
      title: 'Fun Fact',
      description: 'Cuttlefist Are Chameleons of the sea',
      new: false,
      loading: true,
    },
  ],
  loading: false,
  lastUpdated: null,
  error: null,
}

const TYPES = {
  data: observable,
  loading: observable,
  error: observable,
  lastUpdated: observable,
}

export class DashboardStore extends BaseStore {
  /**
   * Gets updated updated data.
   */
  getUpdatedData = action(async () => {
    this.loading = true
    this.error = null

    setTimeout(() => {
      const updatedData = _.map(this.data, (f) => ({ ...f, loading: false }))

      this.data = updatedData
      this.lastUpdated = new Date()
      this.loading = false
    }, 3000)
  })
}

export const dashboard = new DashboardStore(INITIAL_STATE, TYPES)
export const DashboardStoreContext = createContext(dashboard)
