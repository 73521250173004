import React from 'react'
import PropTypes from 'prop-types'

const ActionPersonIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.66667 10H4.66667C3.19391 10 2 11.1939 2 12.6667V13.3333"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 14H9.33325"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52851 10.2229L11.5562 8.19526C11.6812 8.07024 11.8508 8 12.0276 8C12.2044 8 12.374 8.07024 12.499 8.19526L13.138 8.83427C13.3983 9.09462 13.3983 9.51673 13.138 9.77708L11.1103 11.8047C10.9853 11.9298 10.8157 12 10.6389 12H9.66659C9.48249 12 9.33325 11.8508 9.33325 11.6667V10.6943C9.33325 10.5175 9.40349 10.348 9.52851 10.2229Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="7.33342"
      cy="4.66667"
      r="2.66667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionPersonIcon.defaultProps = {
  className: '',
}

ActionPersonIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionPersonIcon
