// Utils and Service

import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the manufacturers for the specified manufacturerId and repair order status.
 * @param {string} manufacturerId
 * @param {string} repairOrderStatus
 * @param {func} setLoading
 * @param {func} setError
 */
export const getManufacturerActions = async (
  manufacturerId,
  repairOrderStatus,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  const repairOrderStatusValue = repairOrderStatus ? 'open' : 'closed'
  try {
    const response = await axios.get(
      `/manufacturers/${manufacturerId}/actions/?status=${repairOrderStatusValue}`,
    )
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
