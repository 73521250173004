// import { Textarea } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { useForm } from 'react-hook-form'
import { Modal } from '../../components/Modal'
import { Textarea } from '../../components/Textarea'

// Utils & Services
import { deleteRepairOrder } from '../../services/repairOrders.service'
import { toast } from '../../utils/helpers'

/**
 *
 * DeleteRepairOrderModal
 *
 */
const DeleteRepairOrderModal = ({ isOpen, setIsOpen, repairOrderId, dealerId }) => {
  const navigate = useNavigate()

  // State
  const [loading, setLoading] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      deleteReasonNotes: '',
    },
  })

  const onSubmit = async (data) => {
    await deleteRepairOrder(dealerId, repairOrderId, data, setLoading, handleError, () => {
      handleSuccess('Repair order deleted.')
      setIsOpen(false)
      navigate(`/dealer/${dealerId}/detail`)
    })
  }

  const onCloseModal = () => {
    setIsOpen(false)
    clearErrors()
    reset()
  }

  return (
    <Modal
      open={isOpen}
      title="Delete Reason"
      setOpen={setIsOpen}
      loading={loading}
      onClose={onCloseModal}
      content={
        <div className="mt-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Textarea
              {...register('deleteReasonNotes', { required: 'This field is required' })}
              data-testid="deleteReasonNotes"
              id="deleteReasonNotes"
              required
              label="Reason"
              error={errors.deleteReasonNotes && errors.deleteReasonNotes.message}
            />
          </form>
        </div>
      }
      actions={[
        {
          type: 'submit',
          label: 'Submit',
          onClick: handleSubmit(async (data) => {
            onSubmit(data)
          }),
        },
        { type: 'cancel', label: 'Cancel', onClick: onCloseModal },
      ]}
    />
  )
}

export default DeleteRepairOrderModal

DeleteRepairOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  dealerId: PropTypes.string.isRequired,
}
