import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal } from '../../components/Modal'

// Utils & Services
import { updateLineItem } from '../../services/lineItem.service'
import { toast } from '../../utils/helpers'

/**
 *
 * ResolveLineItemModal
 *
 */
const ResolveLineItemModal = ({
  isOpen,
  setIsOpen,
  dealerId,
  repairOrderId,
  lineItem,
  setUpdatedLineItem,
  getUpdatedRepairOrder,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  return (
    <Modal
      open={isOpen}
      title="Are you sure?"
      setOpen={setIsOpen}
      loading={loading}
      content={
        <div>
          <div className="my-2 flex h-full items-center justify-center">
            <p>
              Please confirm that the following action items are complete and all necessary
              documentation has been added. Once resolved, you will no longer be able to update
              Line Item details or add documentation.
            </p>
          </div>
          <div className="mx-2 my-4 flex flex-wrap justify-center">
            <div className="w-full sm:w-1/2">
              <h3 className="text-charcoal-900 mb-2 pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                Actions
              </h3>
              <ul className="list-disc pl-5">
                {lineItem.actionsNeeded.map(({ action }) => (
                  <li key={action.id}>{action.name}</li>
                ))}
              </ul>
            </div>
            <div className="w-full px-3 sm:w-1/2">
              <h3 className="text-charcoal-900 mb-2 pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]">
                Attached Documents
              </h3>
              {lineItem.files.length === 0 ? (
                <p>No files attached.</p>
              ) : (
                <ul className="list-disc pl-5">
                  {lineItem.files.map((file) => (
                    <li key={file.id}>{file.displayName}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      }
      actions={[
        {
          type: 'submit',
          label: 'Resolve',
          onClick: () =>
            updateLineItem(
              dealerId,
              repairOrderId,
              lineItem.id,
              {
                markAsResolved: true,
              },
              setLoading,
              handleError,
              () => {
                handleSuccess('Line item resolved.')
                setIsOpen(false)
                setUpdatedLineItem((prevState) => ({ ...prevState, state: 'Resolved' }))
                getUpdatedRepairOrder()
              },
            ),
        },
        { type: 'cancel', label: 'Cancel' },
      ]}
    />
  )
}

export default ResolveLineItemModal

ResolveLineItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  lineItem: PropTypes.object.isRequired,
  setUpdatedLineItem: PropTypes.func.isRequired,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
}
