import React from 'react'
import PropTypes from 'prop-types'

const FilterIcon = ({ className }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.123436 0.939167C1.30177 2.45 3.4776 5.25 3.4776 5.25V8.75C3.4776 9.07083 3.7401 9.33333 4.06094 9.33333H5.2276C5.54844 9.33333 5.81094 9.07083 5.81094 8.75V5.25C5.81094 5.25 7.98094 2.45 9.15927 0.939167C9.45677 0.554167 9.1826 0 8.69844 0H0.584269C0.100102 0 -0.174064 0.554167 0.123436 0.939167Z"
      fill="inherit"
    />
  </svg>
)

FilterIcon.defaultProps = {
  className: '',
}

FilterIcon.propTypes = {
  className: PropTypes.string,
}

export default FilterIcon
