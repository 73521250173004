import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import React from 'react'
import { DoubleChevronClosedIcon } from '../../components/DoubleChevronClosedIcon'
import { DoubleChevronOpenIcon } from '../../components/DoubleChevronOpenIcon'

const CollapsibleSection = ({ title, children, defaultOpen }) => (
  <Disclosure defaultOpen={defaultOpen}>
    {({ open }) => (
      <>
        <DisclosureButton className="w-full">
          <div className="flex flex-row items-center gap-4">
            <span className="text-charcoal-900 flex-none text-xl font-semibold">{title}</span>
            <div className="bg-charcoal-400 flex h-[1px] w-full" />
            <button type="button">
              {open ? (
                <DoubleChevronOpenIcon className="fill-blue h-4" />
              ) : (
                <DoubleChevronClosedIcon className="fill-charcoal-900 h-4" />
              )}
            </button>
          </div>
        </DisclosureButton>
        <Transition
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <DisclosurePanel className="p-0 text-gray-500">{children}</DisclosurePanel>
        </Transition>
      </>
    )}
  </Disclosure>
)

CollapsibleSection.defaultProps = {
  defaultOpen: true,
}
CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
}

export default CollapsibleSection
