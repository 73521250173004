import React from 'react'
import PropTypes from 'prop-types'

const DoubleChevronClosedIcon = ({ className }) => (
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.02317 17.3209L11.8934 13.2032C12.1448 12.9907 12.438 12.8844 12.7732 12.8844C13.1083 12.8844 13.4016 12.9907 13.653 13.2032C13.9043 13.4157 14.03 13.6681 14.03 13.9603C14.03 14.2525 13.9043 14.5049 13.653 14.7175L8.81415 18.8351C8.33237 19.2425 7.73537 19.4462 7.02317 19.4462C6.31097 19.4462 5.71397 19.2425 5.23219 18.8351L0.361967 14.7175C0.110601 14.5049 -0.00984517 14.2525 0.000628415 13.9603C0.011102 13.6681 0.142022 13.4157 0.393388 13.2032C0.644754 12.9907 0.943251 12.8844 1.28888 12.8844C1.63451 12.8844 1.93301 12.9907 2.18437 13.2032L7.02317 17.3209ZM7.02317 2.12526L2.18437 6.2164C1.93301 6.42892 1.63974 6.53076 1.30459 6.5219C0.969435 6.51305 0.676175 6.41121 0.424809 6.2164C0.173443 6.00387 0.0425228 5.75149 0.0320492 5.45927C0.0215756 5.16705 0.142022 4.91467 0.393388 4.70215L5.23219 0.611013C5.71397 0.203671 6.31097 0 7.02317 0C7.73537 0 8.33237 0.203671 8.81415 0.611013L13.653 4.70215C13.9043 4.91467 14.0248 5.16705 14.0143 5.45927C14.0038 5.75149 13.8729 6.00387 13.6215 6.2164C13.3702 6.41121 13.0769 6.51305 12.7417 6.5219C12.4066 6.53076 12.1133 6.42892 11.862 6.2164L7.02317 2.12526Z"
      fill="inherit"
    />
  </svg>
)

DoubleChevronClosedIcon.defaultProps = {
  className: '',
}

DoubleChevronClosedIcon.propTypes = {
  className: PropTypes.string,
}

export default DoubleChevronClosedIcon
