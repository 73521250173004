import React from 'react'
import PropTypes from 'prop-types'

const AttachmentIcon = ({ className, unread }) =>
  unread ? (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.375 14.8132L10.682 22.5062C8.92462 24.2636 6.07538 24.2636 4.31802 22.5062C2.56066 20.7488 2.56066 17.8996 4.31802 16.1422L15.2573 5.2029C16.4289 4.03133 18.3283 4.03133 19.4999 5.2029C20.6715 6.37447 20.6715 8.27397 19.4999 9.44554L8.55158 20.3939M8.56066 20.3849C8.55764 20.3879 8.55462 20.3909 8.55158 20.3939M14.2498 10.4529L6.43934 18.2636C5.85355 18.8493 5.85355 19.7991 6.43934 20.3849C7.02211 20.9676 7.9651 20.9706 8.55158 20.3939"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="20" cy="6.07422" r="5" fill="#FF1A25" stroke="#FDFDF9" strokeWidth="2" />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.375 12.739L10.682 20.432C8.92462 22.1893 6.07538 22.1893 4.31802 20.432C2.56066 18.6746 2.56066 15.8254 4.31802 14.068L15.2573 3.12868C16.4289 1.95711 18.3283 1.95711 19.4999 3.12868C20.6715 4.30025 20.6715 6.19975 19.4999 7.37132L8.55158 18.3197M8.56066 18.3107C8.55764 18.3137 8.55462 18.3167 8.55158 18.3197M14.2498 8.37865L6.43934 16.1893C5.85355 16.7751 5.85355 17.7249 6.43934 18.3107C7.02211 18.8934 7.9651 18.8964 8.55158 18.3197"
        stroke="inherit"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )

AttachmentIcon.defaultProps = {
  className: '',
  unread: false,
}

AttachmentIcon.propTypes = {
  className: PropTypes.string,
  unread: PropTypes.bool,
}

export default AttachmentIcon
