import PropTypes from 'prop-types'
import React, { useState } from 'react'

// Components
import { Modal } from '../../components/Modal'

// Utils & Service
import { deleteUser } from '../../services/user.service'
import { toast } from '../../utils/helpers'

const DeleteDealerUserModal = ({ isOpen, setIsOpen, onDelete, user }) => {
  // State
  const [loading, setLoading] = useState(false)

  const handleError = (message) => toast(message, 'error')
  const handleSuccess = (message) => {
    toast(message, 'success')
    setIsOpen(false)
    onDelete()
  }

  if (!user) return null

  return (
    <Modal
      open={isOpen}
      title="Are you sure?"
      setOpen={setIsOpen}
      content={
        <div className="my-4 flex h-full items-center justify-center">
          <p>
            Are you sure you want to delete this dealer user:{' '}
            {`${user.firstName} ${user.lastName}`}?
          </p>
        </div>
      }
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
        },
        {
          type: 'submit',
          label: 'Delete',
          onClick: () => {
            deleteUser(user.id, handleError, setLoading, handleSuccess)
          },
        },
      ]}
      loading={loading}
    />
  )
}

DeleteDealerUserModal.defaultProps = {
  user: null,
}

DeleteDealerUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
  }),
}

export default DeleteDealerUserModal
