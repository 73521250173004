import PropTypes from 'prop-types'
import React, { useContext } from 'react'

// Components
import { Modal } from '../Modal'

// Service
import { logout } from '../../services/user.service'

// Stores
import { RootStoreContext } from '../../stores/RootStore'

const TermsAndConditionsModal = ({ isOpen, setIsOpen, onAccept, isLoggedIn }) => {
  const { clearStore, user } = useContext(RootStoreContext)

  return (
    <Modal
      open={isOpen}
      title="Terms and Conditions"
      onClose={isLoggedIn ? () => {} : onAccept}
      setOpen={setIsOpen}
      description={isLoggedIn && 'You must agree to the terms and conditions to access the site.'}
      content={
        <div className="relative my-4 h-[400px] overflow-y-scroll">
          <p className="text-charcoal-900 text-sm">
            {/* TODO: REPLACE PLACEHOLDER TEXT. */}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur euismod, massa ut
            fermentum tincidunt, eros lacus commodo augue, nec malesuada enim velit at libero.
            Fusce sit amet leo et metus placerat volutpat. Suspendisse potenti. Nulla facilisi.
            Vivamus auctor eros nec nisl fringilla, nec aliquam odio vestibulum. Aliquam erat
            volutpat. Nam ullamcorper, felis ssit amet commodo venenatis, ligula turpis aliquam
            massa, a varius lorem magna eu nisi. Vivamus eget libero nec risus malesuada cursus at
            eu est. Proin nec libero venenatis, ullamcorper enim nec, porttitor quam. Vivamus
            scelerisque magna a orci gravida, a interdum velit egestas. Curabitur dapibus orci non
            sem dictum, sit amet pulvinar sem auctor. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Curabitur euismod, massa ut fermentum tincidunt, eros lacus commodo
            augue, nec malesuada enim velit at libero. Fusce sit amet leo et metus placerat
            volutpat. Suspendisse potenti. Nulla facilisi. Vivamus auctor eros nec nisl fringilla,
            nec aliquam odio vestibulum. Aliquam erat volutpat. Nam ullamcorper, felis sit amet
            commodo venenatis, ligula turpis aliquam massa, a varius lorem magna eu nisi. Vivamus
            eget libero nec risus malesuada cursus at eu est. Proin nec libero venenatis,
            ullamcorper enim nec, porttitor quam. Vivamus scelerisque magna a orci gravida, a
            interdum velit egestas. Curabitur dapibus orci non sem dictum, sit amet pulvinar sem
            auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur euismod,
            massa ut fermentum tincidunt, eros lacus commodo augue, nec malesuada enim velit at
            libero. Fusce sit amet leo et metus placerat volutpat. Suspendisse potenti. Nulla
            facilisi. Vivamus auctor eros nec nisl fringilla, nec aliquam odio vestibulum. Aliquam
            erat volutpat. Nam ullamcorper, felis sit amet commodo venenatis, ligula turpis aliquam
            massa, a varius lorem magna eu nisi. Vivamus eget libero nec risus malesuada cursus at
            eu est. Proin nec libero venenatis, ullamcorper enim nec, porttitor quam. Vivamus
            scelerisque magna a orci gravida, a interdum velit egestas. Curabitur dapibus orci non
            sem dictum, sit amet pulvinar sem auctor.
          </p>
          <div className="from-white-dark pointer-events-none sticky bottom-0 left-0 h-[50px] w-full bg-gradient-to-t to-transparent"></div>
        </div>
      }
      actions={
        isLoggedIn
          ? [
              {
                type: 'cancel',
                label: 'Logout',
                onClick: () => logout(user.refreshToken, clearStore),
              },
              {
                type: 'submit',
                label: 'I Agree',
                onClick: onAccept,
              },
            ]
          : [
              {
                type: 'submit',
                label: 'Done',
                onClick: onAccept,
              },
            ]
      }
    />
  )
}

export default TermsAndConditionsModal

TermsAndConditionsModal.defaultProps = {
  isLoggedIn: false,
}

TermsAndConditionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
}
