// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the line items for a repair order.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {func} setLoading
 * @param {func} setError
 */
export const getLineItems = async (
  dealerId,
  repairOrderId,
  setLoading = () => {},
  setError = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.get(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/?expand=actions_needed,actions_needed.action`,
    )
    setLoading(false)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Updates a line item action needed using the specified `payload`.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} lineItemId
 * @param {string} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const updateLineItemActionNeeded = async (
  dealerId,
  repairOrderId,
  lineItemId,
  actionNeededId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/actions-needed/${actionNeededId}/?expand=action,marked_complete_by/`,
      payload,
    )

    setLoading(false)
    setSuccess('Action needed updated.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Creates a line item action needed using the specified `payload`.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const createLineItemActionNeeded = async (
  dealerId,
  repairOrderId,
  lineItemId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(
      `dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/actions-needed/?expand=action,marked_complete_by/`,
      payload,
    )
    setLoading(false)
    setSuccess('Action needed created.', response.id)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Deletes a line item action needed.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} lineItemId
 * @param {string} actionNeededId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const deleteLineItemActionNeeded = async (
  dealerId,
  repairOrderId,
  lineItemId,
  actionNeededId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.delete(
      `dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/actions-needed/${actionNeededId}/`,
    )

    setLoading(false)
    setSuccess('Action needed deleted.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
