import React from 'react'
import PropTypes from 'prop-types'

const ActionDollarIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 14H4.33333C3.59667 14 3 13.4033 3 12.6667V3.33333C3 2.59667 3.59667 2 4.33333 2H11.6667C12.4033 2 13 2.59667 13 3.33333V12.6667C13 13.4033 12.4033 14 11.6667 14Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99999 5.31504V4.42004"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99999 10.685V11.58"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44624 10.0454C6.67775 10.4248 7.07393 10.6874 7.55246 10.6874H7.99996H8.53337C9.22789 10.6874 9.78995 10.1241 9.78995 9.4308C9.78995 8.85442 9.39735 8.35203 8.83887 8.21121L7.16104 7.79116C6.60257 7.65035 6.20996 7.14796 6.20996 6.57158C6.20996 5.87825 6.77321 5.315 7.46654 5.315H7.99996H8.44745C8.92479 5.315 9.31978 5.57634 9.55129 5.95463"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionDollarIcon.defaultProps = {
  className: '',
}

ActionDollarIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionDollarIcon
