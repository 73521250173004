const options = {
  autoHide: true,
  todayBtn: true,
  clearBtn: true,
  todayBtnText: 'Today',
  clearBtnText: 'Clear',
  theme: {
    background: '',
    todayBtn: '',
    clearBtn: '',
    icons: '',
    text: '',
    disabledText: '',
    input: '',
    inputIcon: '',
    selected: '',
  },
  datepickerClassNames: '',
  defaultDate: new Date(),
  language: 'en',
  weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  inputNameProp: 'date',
  inputIdProp: 'date',
  inputPlaceholderProp: 'Select Date',
  inputDateFormatProp: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  },
}

export default options
