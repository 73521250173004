/* eslint-disable react/prop-types */
import React from 'react'

/* FormHeader */
const FormHeader = ({ children, title }) => (
  <div>
    <h2 className="text-gray text-center text-3xl font-bold tracking-tight">{title}</h2>
    {children}
  </div>
)

FormHeader.propTypes = {}

export default FormHeader
