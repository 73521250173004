import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 * Text Input
 */
const TextInput = forwardRef(
  (
    {
      autoComplete,
      className,
      containerClassName,
      currency,
      inputStyles,
      endIcon,
      error,
      fullWidth,
      hideError,
      icon,
      id,
      label,
      name,
      placeholder,
      type,
      ...rest
    },
    ref,
  ) => (
    <div
      className={mergeClassNames(
        `flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`,
        containerClassName,
      )}
    >
      <label
        htmlFor={id}
        className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
      >
        {label}
      </label>
      <div
        className={mergeClassNames(
          'bg-white-light relative flex w-full appearance-none rounded-md border-[1px] px-3 py-2.5 pr-4',
          className,
        )}
      >
        {icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
            {icon}
          </div>
        )}

        {currency ? (
          <CurrencyInput
            id={id}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            type={type}
            className={mergeClassNames(
              'placeholder:text-charcoal-400 disabled:text-charcoal-400 w-full border-none bg-transparent p-0 text-base font-medium placeholder:text-base placeholder:font-normal focus:outline-none focus:ring-0 disabled:cursor-not-allowed',
              inputStyles,
            )}
            {...rest}
            ref={ref}
          />
        ) : (
          <input
            id={id}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            type={type}
            className={mergeClassNames(
              'placeholder:text-charcoal-400 disabled:text-charcoal-400 w-full border-none bg-transparent p-0 text-base font-medium placeholder:text-base placeholder:font-normal focus:outline-none focus:ring-0 disabled:cursor-not-allowed',
              inputStyles,
            )}
            {...rest}
            ref={ref}
          />
        )}

        {endIcon && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">{endIcon}</div>
        )}
      </div>

      {!hideError && (
        <div
          className="mt-1 w-full bg-transparent px-2 py-1 text-center"
          aria-hidden={error ? 'false' : 'true'}
        >
          <p className="text-error min-h-[24px] text-sm font-medium" id={`error:${name}`}>
            {error || ' '}
          </p>
        </div>
      )}
    </div>
  ),
)

TextInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  containerClassName: null,
  currency: false,
  inputStyles: '',
  endIcon: null,
  error: '',
  fullWidth: false,
  hideError: false,
  icon: null,
  id: '',
  label: '',
  name: '',
  nunito: false,
  placeholder: '',
  type: 'text',
}

TextInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  currency: PropTypes.bool,
  inputStyles: PropTypes.string,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideError: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
}

export default TextInput
