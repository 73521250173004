/* eslint-disable indent */
import React, { forwardRef, useContext, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import PropTypes from 'prop-types'

import defaultOptions from '../Options'
import DatePickerPopup from './DatePickerPopup'
import DatePickerProvider, { DatePickerContext } from './DatePickerProvider'

const DatePicker = ({
  value,
  children,
  options,
  onChange,
  classNames,
  show,
  setShow,
  selectedDateState,
}) => (
  <div className={twMerge('w-full', classNames)}>
    <DatePickerProvider
      value={value}
      options={options}
      onChange={onChange}
      show={show}
      setShow={setShow}
      selectedDateState={selectedDateState}
    >
      <DatePickerMain value={value} options={options}>
        {children}
      </DatePickerMain>
    </DatePickerProvider>
  </div>
)

DatePicker.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node,
  options: PropTypes.object,
  onChange: PropTypes.func,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  selectedDateState: PropTypes.func,
}

const DatePickerMain = ({ value, options: customOptions, children }) => {
  const options = { ...defaultOptions, ...customOptions }
  const { setShow, show } = useContext(DatePickerContext)
  const InputRef = useRef(null)
  const DatePickerRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!(InputRef?.current && DatePickerRef?.current)) return
      if (
        !InputRef.current.contains(event.target) &&
        !DatePickerRef.current.contains(event.target)
      ) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', (event) => handleClickOutside(event))

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event))
    }
  }, [DatePickerRef, InputRef, setShow])

  return (
    <>
      {children || (
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <CalendarIcon />
          </div>
          <Input
            ref={InputRef}
            idProp={options?.inputIdProp}
            valueProp={value}
            nameProp={options?.inputNameProp}
            placeholderProp={options?.inputPlaceholderProp}
            dateFormat={options?.inputDateFormatProp}
          />
        </div>
      )}
      {show && <DatePickerPopup ref={DatePickerRef} />}
    </>
  )
}

DatePickerMain.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array,
  children: PropTypes.node,
}

const Input = forwardRef(({ nameProp, idProp, placeholderProp, valueProp, dateFormat }, ref) => {
  const { setShow, selectedDate, showSelectedDate, options, getFormattedDate } =
    useContext(DatePickerContext)

  return (
    <input
      ref={ref}
      type="text"
      name={nameProp}
      id={idProp}
      className={twMerge(
        'block w-full rounded-lg border border-gray-300 bg-gray-50 py-2.5 pl-9 pr-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500',
        options?.theme?.input,
      )}
      placeholder={placeholderProp}
      value={
        // eslint-disable-next-line no-nested-ternary
        valueProp
          ? getFormattedDate(valueProp, dateFormat)
          : selectedDate && showSelectedDate
          ? getFormattedDate(selectedDate, dateFormat)
          : ''
      }
      onFocus={() => setShow(true)}
      readOnly
    />
  )
})

Input.defaultProps = {
  nameProp: 'date',
  idProp: 'date',
  placeholderProp: 'Select Date',
  dateFormat: null,
  valueProp: null,
}

Input.propTypes = {
  nameProp: PropTypes.string,
  idProp: PropTypes.string,
  placeholderProp: PropTypes.string,
  dateFormat: PropTypes.object,
  valueProp: PropTypes.object,
}

Input.displayName = 'Input'

const CalendarIcon = () => {
  const { options } = useContext(DatePickerContext)

  return (
    <svg
      aria-hidden="true"
      className={twMerge('h-5 w-5 text-gray-500 dark:text-gray-400', options?.theme?.inputIcon)}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default DatePicker
