import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 *
 * CheckBox
 *
 */
const CheckBox = forwardRef(
  ({ className, disabled, label, name, onChange, value, ...rest }, ref) => (
    <div className={mergeClassNames('flex  items-center space-x-2', className)}>
      <input
        disabled={disabled}
        id={name}
        name={name}
        type="checkbox"
        className="appearance-none rounded-sm border-2 border-blue-800 checked:bg-blue-800 disabled:cursor-not-allowed disabled:opacity-50"
        checked={value}
        onChange={onChange}
        ref={ref}
        {...rest}
      />

      <label htmlFor={name} className="text-sm font-medium text-gray-700">
        {label}
      </label>
    </div>
  ),
)

CheckBox.defaultProps = {
  className: null,
  disabled: false,
  label: null,
}

CheckBox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}

export default CheckBox
