import React from 'react'
import PropTypes from 'prop-types'

const ActionCodeIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.66659 5.33331V10.6666"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33333 5.33331V10.6666"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 9.33333H10.6666"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 6.66665H10.6666"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 4.66667V11.3333C14 12.806 12.806 14 11.3333 14H4.66667C3.194 14 2 12.806 2 11.3333V4.66667C2 3.194 3.194 2 4.66667 2H11.3333C12.806 2 14 3.194 14 4.66667Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionCodeIcon.defaultProps = {
  className: '',
}

ActionCodeIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionCodeIcon
