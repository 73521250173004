import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Modal } from '../../components/Modal'

// Utils & Services
import { updateRepairOrder } from '../../services/repairOrders.service'
import { toast } from '../../utils/helpers'

/**
 *
 * ResolveRepairOrderModal
 *
 */
const ResolveRepairOrderModal = ({ isOpen, setIsOpen, repairOrder, repairOrderId, dealerId }) => {
  const navigate = useNavigate()

  // State
  const [loading, setLoading] = useState(false)

  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const writtenOffLineItems = repairOrder.lineItems.filter(
    (lineItem) => lineItem.state === 'Resolved - Written Off',
  )
  return (
    <Modal
      open={isOpen}
      title="Are You Sure?"
      setOpen={setIsOpen}
      loading={loading}
      content={
        <div className="mt-8">
          {writtenOffLineItems.length === 0 ? (
            <p className="text-gray mb-8 text-sm">No written off line items to confirm.</p>
          ) : (
            <>
              <p className="text-gray mb-8 text-sm">
                Please review the following write-off reasons and confirm that the Repair Order
                should be resolved.
              </p>
              <section className="ml-4">
                {writtenOffLineItems.map((lineItem) => (
                  <div className="mb-8">
                    <h3 className="mb-2 text-sm uppercase leading-3">{`Line Item ${lineItem.lineName}`}</h3>
                    <div className="ml-2 flex flex flex-col text-sm">
                      <div>
                        <span className="pr-2" aria-hidden="true">
                          •
                        </span>
                        <span className="pr-2">Department:</span>
                        <span>{lineItem.writeOffDepartment}</span>
                      </div>
                      <div>
                        <span className="pr-2" aria-hidden="true">
                          •
                        </span>
                        <span className="pr-2">Notes:</span>
                        <span>
                          {lineItem.writeOffNotes
                            ? lineItem.writeOffNotes
                            : 'No reasons reported.'}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </section>
            </>
          )}
        </div>
      }
      actions={[
        {
          type: 'submit',
          label: 'Resolve',
          onClick: async () => {
            await updateRepairOrder(
              dealerId,
              repairOrderId,
              { state: 'Resolved' },
              setLoading,
              handleError,
              () => {
                handleSuccess(`Repair order has been resolved.`)
                setIsOpen(false)
                navigate(`/dealer/${dealerId}/detail`)
              },
            )
          },
        },
        { type: 'cancel', label: 'Cancel' },
      ]}
    />
  )
}

export default ResolveRepairOrderModal

ResolveRepairOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  repairOrder: PropTypes.object.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  dealerId: PropTypes.string.isRequired,
}
