import React from 'react'
import PropTypes from 'prop-types'

const AddressCardIcon = ({ className }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 6.25C4.73858 6.25 2.5 8.48858 2.5 11.25V28.75C2.5 31.5114 4.73858 33.75 7.5 33.75H32.5C35.2614 33.75 37.5 31.5114 37.5 28.75V11.25C37.5 8.48858 35.2614 6.25 32.5 6.25H7.5ZM14.375 11.25C12.3039 11.25 10.625 12.9289 10.625 15C10.625 17.0711 12.3039 18.75 14.375 18.75C16.4461 18.75 18.125 17.0711 18.125 15C18.125 12.9289 16.4461 11.25 14.375 11.25ZM7.91985 25.7546C8.88483 23.1274 11.409 21.25 14.3749 21.25C17.3408 21.25 19.865 23.1274 20.83 25.7546C21.0452 26.3405 20.7952 26.9954 20.2443 27.2888C18.493 28.2218 16.4938 28.75 14.3749 28.75C12.2561 28.75 10.2569 28.2218 8.50551 27.2888C7.95463 26.9954 7.70465 26.3405 7.91985 25.7546ZM25 13.75C24.3096 13.75 23.75 14.3096 23.75 15C23.75 15.6904 24.3096 16.25 25 16.25H31.25C31.9404 16.25 32.5 15.6904 32.5 15C32.5 14.3096 31.9404 13.75 31.25 13.75H25ZM23.75 20C23.75 19.3096 24.3096 18.75 25 18.75H31.25C31.9404 18.75 32.5 19.3096 32.5 20C32.5 20.6904 31.9404 21.25 31.25 21.25H25C24.3096 21.25 23.75 20.6904 23.75 20ZM25 23.75C24.3096 23.75 23.75 24.3096 23.75 25C23.75 25.6904 24.3096 26.25 25 26.25H31.25C31.9404 26.25 32.5 25.6904 32.5 25C32.5 24.3096 31.9404 23.75 31.25 23.75H25Z"
      fill="#C3C3CA"
    />
  </svg>
)

AddressCardIcon.defaultProps = {
  className: '',
}

AddressCardIcon.propTypes = {
  className: PropTypes.string,
}

export default AddressCardIcon
