import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import {
  ArchiveBoxIcon,
  ArrowUturnLeftIcon,
  CheckIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
} from '@heroicons/react/24/outline'
import { BellIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react'
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { Button } from '../Button'

// Icons
import { FilterIcon } from '../FilterIcon'

// Stores
import { NotificationStoreContext } from '../../stores/NotificationStore'

// Services
import {
  getNotifications,
  markAllNotificationsAsArchived,
  markAllNotificationsAsRead,
  markNotificationAsArchived,
  markNotificationAsRead,
  markNotificationAsUnarchived,
  markNotificationAsUnread,
} from '../../services/notifications.service'

// Utils
import { joinClassNames, toast } from '../../utils/helpers'

const FILTER_OPTIONS = [
  { label: 'Unread & Read', value: 'unread_read', filter: 'archived=false' },
  { label: 'Read', value: 'read', filter: 'unread=false&archived=false' },
  { label: 'Archived', value: 'archived', filter: 'archived=true' },
]

const Notifications = observer(() => {
  // Context
  const {
    currentPage,
    notifications,
    unreadNotificationsCount,
    nextPage,
    triggerAlert,
    hasNewNotifications,
    isMenuOpen,
    setCurrentPage,
    setNotifications,
    setTriggerAlert,
    toggleMenuOpen,
    setMenuOpen,
    setHasNewNotifications,
  } = useContext(NotificationStoreContext)

  // State
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [filter, setFilter] = useState('unread_read')

  const buttonRef = useRef()
  const panelRef = useRef()

  const handleError = (message) => toast(message, 'error')

  /**
   * Manually close the popover panel if the user clicks outside of the button or panel.
   * @param {object} event
   */
  const handleClickOutside = (event) => {
    if (!buttonRef.current?.contains(event.target) && !panelRef.current?.contains(event.target)) {
      event.stopPropagation()
      setMenuOpen(false)
    }
  }

  /**
   * Set up the event listener for clicking outside of the popover panel.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  /**
   * Trigger the alert animation when there are new notifications and reset it after 2 seconds.
   */
  useEffect(() => {
    if (triggerAlert) {
      setTimeout(() => {
        setTriggerAlert(false)
      }, 1500)
    }
  }, [triggerAlert])

  /**
   * Get updated notifications based on the filter.
   */
  useEffect(() => {
    if (isMenuOpen) {
      setCurrentPage(1)
      fetchNotifications()
    }
  }, [filter, isMenuOpen])

  const fetchNotifications = () => {
    // Reset the new notification state since we are loading all notifications
    setHasNewNotifications(false)

    getNotifications(
      `/notifications/?${_.find(FILTER_OPTIONS, (f) => f.value === filter)?.filter}`,
      currentPage,
      setLoading,
      handleError,
      setNotifications,
      true,
    )
  }

  const NOTIFICATION_SETTING_OPTIONS = useMemo(() => {
    const options = []

    if (notifications.length > 0) {
      options.unshift({
        label: 'Mark All Read',
        onClick: () =>
          markAllNotificationsAsRead(setLoading, handleError, () => {
            fetchNotifications()
          }),
      })
      options.unshift({
        label: 'Mark All Archived',
        onClick: () =>
          markAllNotificationsAsArchived(setLoading, handleError, () => {
            fetchNotifications()
          }),
      })
    }

    return options
  }, [notifications])

  /**
   * Marks the notification as read or unread.
   * @param {object} notification
   */
  const handleToggleRead = async (notification) => {
    if (notification.unread) {
      await markNotificationAsRead(notification.id, setLoading, handleError, () => {
        fetchNotifications()
      })
    } else {
      await markNotificationAsUnread(notification.id, setLoading, handleError, () => {
        fetchNotifications()
      })
    }
  }

  /**
   * Marks the notification as archived or unarchived.
   * @param {object} notification
   */
  const handleToggleArchive = async (notification) => {
    if (notification.archived) {
      await markNotificationAsUnarchived(notification.id, setLoading, handleError, () => {
        fetchNotifications()
      })
    } else {
      await markNotificationAsArchived(notification.id, setLoading, handleError, () => {
        fetchNotifications()
      })
    }
  }

  const renderNotifications = () => {
    if (notifications.length === 0) {
      return (
        <div className="flex justify-center py-4">
          <span className="text-gray-500">No Notifications</span>
        </div>
      )
    }

    return _.map(notifications, (notification, i) => (
      <Link
        onClick={() => setMenuOpen(false)}
        to={`/dealer/${notification.data.dealerId}/repair-order/${notification.data.roId}`}
        className={mergeClassNames(
          'group relative flex flex-row justify-between gap-2 px-1 py-2 hover:bg-gray-100 sm:px-2 sm:py-4',
          i < notifications.length - 1 && 'border-b border-gray-200',
        )}
      >
        <div className="flex flex-row items-start gap-2">
          <div
            className={mergeClassNames(
              'mt-2 h-2 w-2 flex-none rounded-full',
              !notification.unread || notification.archived
                ? 'hidden bg-transparent sm:block'
                : 'bg-blue',
            )}
          />

          <span className="text-left">{notification.description}</span>
        </div>

        <div className="flex h-full flex-none flex-col justify-between">
          <span className="flex-none text-xs font-semibold uppercase text-black">
            {dayjs(notification.timestamp).format('MMM DD')}
          </span>

          <div className="flex flex-row gap-2">
            {!notification.archived && (
              <>
                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    handleToggleRead(notification)
                  }}
                  aria-label={notification.unread ? 'Mark as Read' : 'Mark as Unread'}
                >
                  {notification.unread ? (
                    <EnvelopeOpenIcon
                      className="stroke-brownGray-dark hover:stroke-midnight w-4 stroke-2"
                      title="Mark read"
                    />
                  ) : (
                    <EnvelopeIcon
                      className="stroke-brownGray-dark hover:stroke-midnight w-4 stroke-2"
                      title="Mark unread"
                    />
                  )}
                </button>

                <button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    handleToggleArchive(notification)
                  }}
                  aria-label="Archive"
                >
                  <ArchiveBoxIcon
                    className="stroke-brownGray-dark hover:stroke-midnight w-4 stroke-2"
                    title="Archive"
                  />
                </button>
              </>
            )}
            {notification.archived && (
              <button
                type="button"
                className="ml-auto"
                onClick={(event) => {
                  event.stopPropagation()
                  event.preventDefault()
                  handleToggleArchive(notification)
                }}
                aria-label="Unarchive"
              >
                <ArrowUturnLeftIcon
                  className="stroke-brownGray-dark hover:stroke-midnight w-4 stroke-2"
                  title="Unarchive"
                />
              </button>
            )}
          </div>
        </div>
      </Link>
    ))
  }

  return (
    <Popover className="relative">
      <button className="pt-2" onClick={() => toggleMenuOpen()} type="button" ref={buttonRef}>
        <BellIcon className="w-8 fill-white" />
        {unreadNotificationsCount > 0 && (
          <div
            className={joinClassNames(
              triggerAlert ? 'motion-safe:animate-bounce-once' : '',
              'bg-blue absolute -end-2 top-0 inline-flex h-6 w-6 items-center justify-center rounded-full text-xs font-bold text-white',
            )}
          >
            {unreadNotificationsCount}
          </div>
        )}
      </button>

      {isMenuOpen && (
        <PopoverPanel
          className="absolute -right-14 z-10 flex w-screen translate-x-1 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in sm:right-0 sm:max-w-max sm:translate-x-4 sm:px-4"
          ref={panelRef}
          static
          transition
        >
          <div className="flex w-screen flex-auto flex-col overflow-hidden rounded-3xl bg-white px-2 py-3 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 sm:max-w-md">
            <div className="flex flex-row items-start justify-between px-2">
              <div className="flex flex-col">
                <span className="font-semibold">NOTIFICATIONS</span>
                <span className="font-light italic text-gray-600">
                  {_.find(FILTER_OPTIONS, (f) => f.value === filter)?.label}
                </span>
              </div>

              {hasNewNotifications && (
                <Button
                  background="bg-background"
                  outlined
                  label="Load New Notifications"
                  onClick={() => fetchNotifications()}
                  size="xs"
                />
              )}

              <div className="flex flex-row gap-2">
                {loading && (
                  <span className="text-xs italic text-gray-700">Syncing Notifications...</span>
                )}

                {NOTIFICATION_SETTING_OPTIONS.length > 0 && (
                  <Menu as="div" className="relative overflow-visible">
                    <MenuButton
                      className="flex rounded-full bg-transparent text-sm focus:outline-none"
                      data-testid="notification-settings-dropdown"
                    >
                      <span className="sr-only">Open notification settings menu</span>
                      <EllipsisHorizontalIcon className="block h-6 stroke-black" />
                    </MenuButton>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-0 z-20 -mt-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                        {_.map(NOTIFICATION_SETTING_OPTIONS, (option) => (
                          <MenuItem key={option.label}>
                            {() => (
                              <button
                                type="button"
                                className="text-primary block w-full px-4 py-0.5 text-start text-sm hover:bg-gray-100"
                                onClick={option.onClick}
                              >
                                {option.label}
                              </button>
                            )}
                          </MenuItem>
                        ))}
                      </MenuItems>
                    </Transition>
                  </Menu>
                )}

                <Menu as="div" className="relative overflow-visible">
                  <MenuButton
                    className="flex rounded-full bg-transparent text-sm focus:outline-none"
                    data-testid="notification-settings-dropdown"
                  >
                    <span className="sr-only">Open notification settings menu</span>
                    <FilterIcon className="block h-6 stroke-black" />
                  </MenuButton>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-20 -mt-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {_.map(FILTER_OPTIONS, (option) => (
                        <MenuItem key={option.label}>
                          {() => (
                            <button
                              type="button"
                              className="text-primary flex w-full flex-row items-center justify-between px-4 py-0.5 text-start text-sm hover:bg-gray-100"
                              onClick={() => {
                                setCurrentPage(1)
                                setFilter(option.value)
                              }}
                            >
                              {option.label}

                              {filter === option.value && (
                                <>
                                  <span className="sr-only">Selected</span>
                                  <CheckIcon className="stroke-midnight block h-4 stroke-2" />
                                </>
                              )}
                            </button>
                          )}
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>

            <div className="flex h-screen flex-col overflow-auto sm:h-full sm:max-h-80">
              {renderNotifications()}
            </div>

            {nextPage && (
              <button
                className="text-primary hover:text-midnight flex w-full justify-center"
                disabled={loadingMore}
                onClick={() => {
                  setCurrentPage(currentPage + 1)
                  getNotifications(
                    nextPage,
                    currentPage + 1,
                    setLoadingMore,
                    handleError,
                    setNotifications,
                    true,
                  )
                }}
                type="button"
              >
                See More
              </button>
            )}
          </div>
        </PopoverPanel>
      )}
    </Popover>
  )
})

export default Notifications
