import React from 'react'
import PropTypes from 'prop-types'

const ActionPaperIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 2H4.00008C3.26341 2 2.66675 2.59667 2.66675 3.33333V12.6667C2.66675 13.4033 3.26341 14 4.00008 14H12.0001C12.7367 14 13.3334 13.4033 13.3334 12.6667V3.33333C13.3334 2.59667 12.7367 2 12.0001 2Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33341 5L7.66675 6.66667L6.66675 5.66667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 9.33333H10.6666"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33325 11.3333H10.6666"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionPaperIcon.defaultProps = {
  className: '',
}

ActionPaperIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionPaperIcon
