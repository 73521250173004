export const firstDateOfMonth = (selectedYear, selectedMonth, date) => {
  const newDate = new Date(0)
  newDate.setFullYear(selectedYear, selectedMonth, date)
  return newDate.setHours(0, 0, 0, 0)
}

export const addDays = (date, amount) => {
  const newDate = new Date(date)
  return newDate.setDate(newDate.getDate() + amount)
}

export const dayDiff = (day, from) => (day - from + 7) % 7

export const dayOfTheWeekOf = (baseDate, dayOfWeek, weekStart = 0) => {
  const baseDay = new Date(baseDate).getDay()
  return addDays(baseDate, dayDiff(dayOfWeek, weekStart) - dayDiff(baseDay, weekStart))
}

export const addMonths = (date, amount) => {
  const newDate = date
  const monthsToSet = newDate.getMonth() + amount
  let expectedMonth = monthsToSet % 12
  if (expectedMonth < 0) {
    expectedMonth += 12
  }

  const time = newDate.setMonth(monthsToSet)
  return newDate.getMonth() !== expectedMonth ? newDate.setDate(0) : time
}

export const addYears = (date, amount) => {
  const newDate = date
  const expectedMonth = newDate.getMonth()
  const time = newDate.setFullYear(newDate.getFullYear() + amount)
  return expectedMonth === 1 && newDate.getMonth() === 2 ? newDate.setDate(0) : time
}

export const getFormattedDate = (language, date, options) => {
  let defaultOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  if (options) defaultOptions = options

  return new Intl.DateTimeFormat(language, defaultOptions).format(date)
}

export const goToPrevNext = (view, date, direction) => {
  switch (view) {
    case 'days':
      return addMonths(date, direction)
    case 'months':
      return addYears(date, direction)
    case 'years':
      return addYears(date, direction * 10)
    case 'decades':
      return addYears(date, direction * 100)
    default:
      return addYears(date, direction * 10)
  }
}

export const startOfYearPeriod = (date, years) => {
  const year = date.getFullYear()
  return Math.floor(year / years) * years
}
