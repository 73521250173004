import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'

// Utils & Services
import { createLineItem, updateLineItem } from '../../services/lineItem.service'
import { toast } from '../../utils/helpers'

const LineItemModal = ({
  isOpen,
  setIsOpen,
  dealerId,
  repairOrderId,
  lineItem,
  getUpdatedRepairOrder,
  defaultTechnician,
  closeModal,
}) => {
  // State
  const [loading, setLoading] = useState(false)

  const onCloseModal = () => {
    closeModal()
    reset({ lineName: '', claimAmount: '' })
  }

  const isEditMode = Boolean(lineItem)

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    control,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      lineName: '',
      claimAmount: '',
    },
  })

  const handleSuccess = (message) => {
    toast(message, 'success')
    onCloseModal()
    getUpdatedRepairOrder()
  }

  const handleError = (message) => {
    if (
      message.length > 0 &&
      message[0] === 'Line item with this Repair order and Line name already exists.'
    ) {
      setError('lineName', {
        type: 'manual',
        message: message[0],
      })
    } else {
      toast(message, 'error')
    }
  }

  useEffect(() => {
    if (isEditMode) {
      reset({
        lineName: lineItem.lineName,
        claimAmount: lineItem.claimAmount,
      })
    }
  }, [isEditMode, lineItem])

  const onSubmit = async (data) => {
    const formattedData = {
      ...data,
      lineName: data.lineName.toUpperCase(),
      claimAmount: parseFloat(data.claimAmount.replace(/[^0-9.]+/g, '')).toFixed(2),
    }

    if (isEditMode) {
      await updateLineItem(
        dealerId,
        repairOrderId,
        lineItem.id,
        formattedData,
        setLoading,
        handleError,
        handleSuccess,
      )
    } else {
      await createLineItem(
        dealerId,
        repairOrderId,
        { ...formattedData, technician: defaultTechnician },
        setLoading,
        handleError,
        handleSuccess,
      )
    }
  }

  return (
    <Modal
      open={isOpen}
      title={isEditMode ? 'Edit Line Item' : 'Add Line Item'}
      setOpen={setIsOpen}
      loading={loading}
      onClose={onCloseModal}
      content={
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-8 flex h-full w-full flex-row gap-4 pt-2"
        >
          <TextInput
            data-testid="lineItemName"
            error={errors.lineName && (errors.lineName.message || 'This field is required')}
            label="Line ID"
            id="lineName"
            name="lineName"
            placeholder=""
            required
            fullWidth
            inputStyles="uppercase"
            {...register('lineName', { required: true })}
          />

          <Controller
            name="claimAmount"
            control={control}
            rules={{
              required: 'This field is required',
            }}
            defaultValue=""
            render={({ field: { value, onChange, ...field } }) => (
              <TextInput
                data-testid="lineItemClaimAmount"
                error={errors.claimAmount && errors.claimAmount.message}
                label="Amount"
                id="claimAmount"
                placeholder=""
                fullWidth
                currency
                prefix="$"
                className="text-base"
                {...field}
                value={value}
                onChange={(e) => {
                  let rawValue = e.target.value

                  const decimalCount = (rawValue.match(/\./g) || []).length
                  const hasMultipleDecimals = decimalCount > 1

                  if (hasMultipleDecimals) {
                    setError('claimAmount', {
                      type: 'custom',
                      message: 'Cannot have multiple decimals',
                    })
                    // Return without updating the value to prevent adding a second decimal
                    return
                  }
                  clearErrors('claimAmount')

                  // Remove non-numeric characters except for the decimal point
                  rawValue = rawValue.replace(/[^0-9.]/g, '')

                  // Do not automatically format to two decimal places unless user inputs them
                  // Only convert to a number if the input is a valid number without unnecessary formatting
                  const numericValue = parseFloat(rawValue)

                  if (!Number.isNaN(numericValue) && rawValue.includes('.')) {
                    // Preserve user input without forcing two decimal places unless they input them
                    const decimalIndex = rawValue.indexOf('.')
                    const decimalPart = rawValue.substring(decimalIndex + 1)

                    if (decimalPart.length > 2) {
                      // If more than two decimal places are entered, truncate to two decimal places
                      rawValue = rawValue.substring(0, decimalIndex + 3)
                    }
                  }

                  // Update the form state with the raw value without moving the cursor
                  onChange(rawValue)
                }}
              />
            )}
          />
        </form>
      }
      actions={[
        {
          type: 'submit',
          label: 'Save',
          onClick: handleSubmit(async (data) => {
            onSubmit(data)
          }),
        },
        { type: 'cancel', label: 'Cancel', onClick: onCloseModal },
      ]}
    />
  )
}

export default LineItemModal

LineItemModal.defaultValues = {
  defaultTechnician: '',
}

LineItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  dealerId: PropTypes.string.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  lineItem: PropTypes.object,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultTechnician: PropTypes.string,
}
