import React from 'react'
import PropTypes from 'prop-types'

const ActionAddIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.1711 6.59261C14.8077 9.35412 13.5383 12.2034 11.0595 13.5769C8.58062 14.9504 5.4918 14.5161 3.4879 12.5122C1.484 10.5083 1.04965 7.41943 2.42319 4.94059C3.79673 2.46175 6.64597 1.19239 9.40748 1.82903"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7493 4.30557V2.19446"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6943 3.25063H13.8054"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="7.99992"
      cy="6.33335"
      r="1.66667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10.6667C5.26461 10.0596 5.86373 9.667 6.52596 9.66669H9.47404C10.1363 9.667 10.7354 10.0596 11 10.6667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionAddIcon.defaultProps = {
  className: '',
}

ActionAddIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionAddIcon
