import { Disclosure } from '@headlessui/react'
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

// Store
import { TaskStoreContext } from '../../stores/TaskStore'

/**
 * TaskProgress
 */
const TaskProgress = observer(() => {
  // Context
  const { details, task, type, status, setDetails, setStatus, setTask, setType } =
    useContext(TaskStoreContext)

  // State
  const [loading, setLoading] = useState(false)

  const totalFiles = task ? task.totalFiles : 0

  /**
   * Set up hook to trigger when `task` changes
   * - This will start a timeout task (if `task` is not null and the interval is not already running)
   * - The interval task will check the status of the task every 3 seconds
   * - When the task is complete, the interval task will be cleared
   */
  useEffect(() => {
    let interval = null
    if (task) {
      setLoading(true)

      if (interval === null) {
        interval = setInterval(async () => {
          if (details && details.checkUpdatedTask) {
            const updatedTask = await details.checkUpdatedTask()
            setTask(updatedTask)
          }
        }, 3000)
      }

      if (task.status !== 'Pending' && task.status !== 'Processing') {
        setLoading(false)
        clearInterval(interval)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [task])

  /**
   * Set up hook to trigger when `task` changes
   * - If the `type` is `Download`
   *   - This will auto-download the zipped file when the task is complete
   * - If the `type` is `Upload`
   *   - This will set the status to `completed` when the task is complete
   */
  useEffect(() => {
    const downloadZippedFile = async () => {
      const response = await fetch(task.zippedFile)
      const blobUrl = await response.blob()
      const objectUrl = window.URL.createObjectURL(blobUrl)
      const downloadLink = document.createElement('a')
      downloadLink.href = objectUrl
      downloadLink.download = details.filename || 'Exported_Documents.zip'
      downloadLink.target = '_blank'
      downloadLink.click()
      downloadLink.remove()

      // Track downloaded so that if the user leaves the listener open, it doesn't
      // continue to fire the download.
      setStatus('completed')
    }

    const downloadFile = async () => {
      const response = await fetch(task.file)
      const blobUrl = await response.blob()
      const objectUrl = window.URL.createObjectURL(blobUrl)
      const downloadLink = document.createElement('a')
      downloadLink.href = objectUrl
      downloadLink.download = details.filename
      downloadLink.target = '_blank'
      downloadLink.click()
      downloadLink.remove()

      // Track downloaded so that if the user leaves the listener open, it doesn't
      // continue to fire the download.
      setStatus('completed')
    }

    if (
      task &&
      !task.zippedFile &&
      type === 'Download' &&
      status !== 'completed' &&
      task.status === 'Exported'
    ) {
      downloadFile()
    } else if (task && type === 'Download' && task.zippedFile && status !== 'completed') {
      downloadZippedFile()
    } else if (task && type === 'Upload' && task.status === 'Imported') {
      setStatus('completed')
    }
  }, [task])

  // Auto-close the popup after 10 seconds if the task is completed or failed
  useEffect(() => {
    if (
      task &&
      (task.status === 'Imported' || task.status === 'Exported' || task.status === 'Failed')
    ) {
      const timer = setTimeout(() => {
        setTask(null)
        setDetails(null)
        setType(null)
      }, 10000)

      return () => clearTimeout(timer)
    }
    return undefined
  }, [task])

  const configureText = () => {
    if (loading && type === 'Download' && !task.zippedFile)
      return `Downloading ${details.label || 'File'}.`
    if (loading && type === 'Download')
      return `Downloading & Zipping ${totalFiles} File${totalFiles > 1 ? 's' : ''}`
    if (loading && type === 'Upload') return `Uploading File...`
    if (task && task.status === 'Expired') return `${type} Expired, Try Again`
    if (task && task.status === 'Failed') return `${type} Failed, Try Again`
    return `${type} finished.`
  }

  const renderCollapseOrCloseIcon = (open) => {
    if (loading) {
      return <div className="w-5">{!open ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation()

          // Clear the interval and task to close the progress listener
          setTask(null)
          setDetails(null)
          setType(null)
        }}
        type="button"
      >
        <XMarkIcon className="stroke-gray w-5" />
      </button>
    )
  }

  const renderStatusIcon = () => {
    if (loading) {
      return (
        <div className="h-10 w-10">
          {/* eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order */}
          <svg className="motion-safe:animate-spin-slow h-10 w-10" viewBox="0 0 40 40">
            <ArrowPathIcon className="h-10 w-10" aria-hidden="true" />
          </svg>
        </div>
      )
    }

    if (task && (task.status === 'Imported' || task.status === 'Exported')) {
      return (
        <div className="w-6">
          <CheckCircleIcon size={48} strokeWidth={2} className="text-success" />
        </div>
      )
    }

    return (
      <div className="flex w-full flex-col place-items-center gap-2 p-1">
        <div className="w-6">
          <XCircleIcon size={48} strokeWidth={2} className="text-error-icon" />
        </div>
        {task.statusMessage && (
          <span className="text-error text-center text-xs">{task.statusMessage}</span>
        )}
      </div>
    )
  }

  return task ? (
    <div className="fixed bottom-3 right-3 z-10 w-72 rounded-lg bg-white px-3 py-2 shadow-md">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full flex-row justify-between">
              <span className="text-sm">{configureText()}</span>

              {renderCollapseOrCloseIcon(open)}
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="flex justify-center">{renderStatusIcon()}</div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  ) : null
})

export default TaskProgress
