export const ACTION_ICONS = {
  'Provide coverage explanation': 'paper-check',
  'Provide labor operation(s)': 'paper-check',
  'Provide approval code (PA)': 'code',
  'Upload/send supporting docs': 'upload',
  'Update customer complaint in DMS': 'person',
  'Provide valid customer complaint': 'person',
  'Update diagnostic code(s)/comments in DMS': 'memo-check',
  'Provide diagnostic code(s)/comments': 'memo-check',
  'Update factory defect comments in DMS': 'memo-check',
  'Provide factory defect comments': 'memo-check',
  'Update repair comments in DMS': 'memo-check',
  'Provide repair comments': 'memo-check',
  'Verify and provide correct mileage': 'mileage',
  'Add advisor/tech to MFG system': 'add',
  'Potential writeoff': 'paper',
  'Other - see problem notes': 'more',
  'Correct parts billed on RO': 'dollar',
  'Need management approval': 'id-badge',
}
