import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

/**
 * PrivateRoute
 *
 * If a user is unauthenticated and attempts to access this route, they will be
 * redirected to the login page, otherwise we return the requested route.
 */
const PrivateRoute = observer(({ component: Component, roles, ...rest }) => {
  // Context
  const { isAuthenticated, user } = useContext(UserStoreContext)
  const location = useLocation()

  // User is not authenticated - redirect to Login with current location passed along
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ next: location?.pathname || null }}
      />
    )
  }

  // User is authenticated but does not have the correct role - redirect to dashboard
  if (user && user.userType && roles && roles.indexOf(user.userType) === -1) {
    return (
      <Navigate
        to={{
          pathname: '/dashboard',
        }}
      />
    )
  }

  // Base case - render the requested component
  return <Component {...rest} />
})

export default PrivateRoute
