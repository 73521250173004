import PropTypes from 'prop-types'
import React, { useState } from 'react'

// Components
import { CheckBox } from '../../components/CheckBox'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { TrashIcon } from '../../components/TrashIcon'

/**
 *
 * RepairOrderActionItem
 *
 */
const RepairOrderActionItem = ({
  item,
  onDelete,
  actionOptions,
  saveActionNeeded,
  loading,
  updateActionItemStatus,
  isLineItemPending,
  canEdit,
}) => {
  // State
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const updatedActionOptions = actionOptions.map((actionOption) => {
    const { name, ...rest } = actionOption
    return { ...rest, label: name }
  })

  const isFieldDisabled = !canEdit || loading || !isLineItemPending

  return (
    <div className="pb-2">
      <div className="flex flex-row items-center gap-2">
        <div className="relative w-5/6 p-2 pl-0">
          <Select
            name={`action-needed-${item.id}`}
            id={`${item.id}`}
            value={updatedActionOptions.find((option) => option.id === item.action.id)}
            placeholder="Select an option"
            options={updatedActionOptions}
            className="bg-white-light rounded-md border-white"
            inputTextClassName="text-charcoal-900 text-md font-normal"
            onChange={(value) => saveActionNeeded(item.id, value.id, value.label)}
            shouldShowError={false}
            disabled={isFieldDisabled || item.completedAt !== null}
          />
        </div>
        <div className="flex w-1/6 flex-none items-center justify-end gap-2">
          <div>
            <CheckBox
              id={`action-completed-${item.id}`}
              name={`action-completed-${item.id}`}
              value={item.completedAt !== null}
              onChange={(event) => {
                updateActionItemStatus(item.id, event.target.checked)
              }}
              disabled={isFieldDisabled || item.id.includes('temp')}
            />
          </div>
          {canEdit && (
            <div className="flex">
              <button
                aria-label="Delete"
                type="button"
                onClick={() => setIsDeleteModalOpen(true)}
                className="inline-flex items-center rounded-lg bg-transparent text-center text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-300 disabled:cursor-not-allowed"
                disabled={isFieldDisabled}
              >
                <TrashIcon className="text-charcoal-800 h-5 w-5" />
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={isDeleteModalOpen}
        title="Are you sure?"
        setOpen={setIsDeleteModalOpen}
        content={
          <div className="my-8 flex h-full items-center justify-center">
            <p className="text-center">
              {`Please confirm that you'd like to delete this action item.`}
            </p>
          </div>
        }
        actions={[
          {
            type: 'submit',
            label: 'Delete',
            onClick: async () => {
              await onDelete(item.id)
              setIsDeleteModalOpen(false)
            },
          },
          { type: 'cancel', label: 'Cancel' },
        ]}
      />
    </div>
  )
}

export default RepairOrderActionItem

RepairOrderActionItem.propTypes = {
  item: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  actionOptions: PropTypes.array.isRequired,
  saveActionNeeded: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateActionItemStatus: PropTypes.func.isRequired,
  isLineItemPending: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
}
