import React, { useEffect, useLayoutEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { RouterStore } from '@superwf/mobx-react-router'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import * as Sentry from '@sentry/browser'
import { Toaster } from 'react-hot-toast'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

// Routes
import { Routes } from './pages/Routes'

// The env variable is not converted to a bool - must check for the explicit string
if (import.meta.env.SENTRY_ENABLED === 'true')
  Sentry.init({
    release: import.meta.env.VITE_SENTRY_RELEASE_GIT_HASH,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  })

// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
if (firebaseConfig.measurementID && import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLED === 'true')
  getAnalytics(app)

// Create mobx store with history
const browserHistory = createBrowserHistory()
const routingStore = new RouterStore(browserHistory)
const stores = {
  routing: routingStore,
}

const ONE_HOUR = 3600000 // milliseconds

const RefreshBrowserRouter = ({ ...rest }) => {
  /**
   * Reloads the window after ONE_HOUR has elapsed
   */
  useEffect(() => {
    const id = setInterval(() => {
      window.location.reload(true)
    }, ONE_HOUR)

    return () => clearInterval(id)
  }, [])

  return <BrowserRouter {...rest} />
}

function App() {
  // State
  const [history, setHistory] = useState({
    action: routingStore.history.action,
    location: routingStore.history.location,
  })

  useLayoutEffect(() => routingStore.subscribe(setHistory), [])

  return (
    <Provider {...stores}>
      <Toaster />
      <RefreshBrowserRouter
        location={history.location}
        navigationType={history.action}
        navigator={routingStore.history}
      >
        <Routes />
      </RefreshBrowserRouter>
    </Provider>
  )
}

export default App
