import React from 'react'
import PropTypes from 'prop-types'

const ActionUploadIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8 13.3334H3.33333C2.59695 13.3334 2 12.7364 2 12V4.00002C2 3.26364 2.59695 2.66669 3.33333 2.66669H6.31371C6.53438 2.66669 6.74076 2.77589 6.86489 2.95834L7.82879 4.37501C7.95292 4.55748 8.15931 4.66669 8.38 4.66669H12.6667C13.403 4.66669 14 5.26364 14 6.00002V12C14 12.7364 13.403 13.3334 12.6667 13.3334H10.6667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66675 14.6666L8.00008 13.3333"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66675 12L8.00008 13.3333"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionUploadIcon.defaultProps = {
  className: '',
}

ActionUploadIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionUploadIcon
