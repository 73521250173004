import React from 'react'
import PropTypes from 'prop-types'

const ActionMileageIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.00008 3.33331V4.66665"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6667 9.33333H14.0001"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.33333H3.33333"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.94289 7.72384C9.46359 8.24454 9.46359 9.08876 8.94289 9.60946C8.42219 10.1302 7.57797 10.1302 7.05727 9.60946C6.53657 9.08876 6.53657 8.24454 7.05727 7.72384C7.57797 7.20314 8.42219 7.20314 8.94289 7.72384"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00008 3.33331V4.66665"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0001 9.33333H12.6667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33333 9.33333H2"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2733 6.39331L8.93994 7.72664"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.94289 7.72384C9.46359 8.24454 9.46359 9.08876 8.94289 9.60946C8.42219 10.1302 7.57797 10.1302 7.05727 9.60946C6.53657 9.08876 6.53657 8.24454 7.05727 7.72384C7.57797 7.20314 8.42219 7.20314 8.94289 7.72384"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2127 12.6667H3.78607C3.28607 12.6667 2.8154 12.394 2.59807 11.944C2.12073 10.9587 1.90407 9.82266 2.0414 8.62666C2.35473 5.88933 4.58607 3.66933 7.32473 3.36999C10.9407 2.97533 14.0001 5.79666 14.0001 9.33333C14.0001 10.266 13.7867 11.1487 13.4067 11.9353C13.1881 12.3893 12.7161 12.6667 12.2127 12.6667V12.6667Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionMileageIcon.defaultProps = {
  className: '',
}

ActionMileageIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionMileageIcon
