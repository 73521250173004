import React from 'react'
import PropTypes from 'prop-types'

const ActionIdBadgeIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.72741 5.43934C7.31319 6.02513 7.31319 6.97487 6.72741 7.56066C6.14162 8.14645 5.19187 8.14645 4.60609 7.56066C4.0203 6.97487 4.0203 6.02513 4.60609 5.43934C5.19187 4.85355 6.14162 4.85355 6.72741 5.43934"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33325 12.3333V3.69398C1.33325 2.94265 1.94259 2.33331 2.69392 2.33331H13.3333C14.0699 2.33331 14.6666 2.92998 14.6666 3.66665V12.3333C14.6666 13.07 14.0699 13.6666 13.3333 13.6666H2.66659C1.92992 13.6666 1.33325 13.07 1.33325 12.3333Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.33333H12.6667"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6 9.00002H10"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.06335 11C7.94669 10.7074 7.76669 10.4447 7.53535 10.2307V10.2307C7.14402 9.86802 6.63069 9.66669 6.09735 9.66669H5.23602C4.70269 9.66669 4.18935 9.86802 3.79802 10.2307V10.2307C3.56669 10.4447 3.38669 10.7074 3.27002 11"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionIdBadgeIcon.defaultProps = {
  className: '',
}

ActionIdBadgeIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionIdBadgeIcon
