import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { SearchIcon } from '../SearchIcon'
import { TextInput } from '../TextInput'

const MultiSelectDropdown = ({
  className,
  customButton,
  id,
  label,
  listBoxClassName,
  onChange,
  openAbove,
  openLeft,
  options,
  placeholder,
  search,
  style,
  value,
  containerClassName,
}) => {
  // State
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  // Ref
  const searchInputRef = useRef()

  let inputTextColor = 'text-gray-500'
  if (!_.isEmpty(value)) inputTextColor = 'text-gray-900'

  useEffect(() => {
    setFilteredOptions(options)
    setSearchTerm('')
  }, [options])

  const buttonClassNames = mergeClassNames(
    'border-brownGray-200 bg-white-light w-full cursor-default rounded-md border-2 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm sm:text-sm sm:leading-6',
    className,
  )

  return (
    <div style={style} className={containerClassName}>
      <Listbox
        onChange={(option) => {
          const selected = _.find(value, (v) => v.id === option.id)

          if (selected) {
            onChange(_.filter(value, (v) => v.id !== option.id))
          } else {
            onChange([...value, option])
          }
        }}
        value={value}
      >
        {({ open }) => (
          <>
            {label && <Label className="block text-sm font-medium text-gray-700">{label}</Label>}

            <div className="relative">
              <ListboxButton
                className={mergeClassNames(
                  'w-full',
                  buttonClassNames,
                  customButton && 'w-full cursor-pointer',
                )}
                disabled={!!customButton}
                id={id}
                data-testid={id}
                onClick={(e) => e.stopPropagation()}
              >
                {customButton || (
                  <>
                    <span className={`block h-6 truncate ${inputTextColor}`}>
                      {value && value.length > 0
                        ? _.map(value, (v) => v.label).join(', ')
                        : placeholder}
                    </span>

                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon className="h-5 w-5 text-blue-800" aria-hidden="true" />
                    </span>
                  </>
                )}
              </ListboxButton>

              <Transition
                className={mergeClassNames(openAbove && 'bottom-full', openLeft && '-right-32')}
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  className={mergeClassNames(
                    'bg-white-light absolute z-30 mt-1 max-h-60 min-w-[250px] overflow-auto rounded-lg px-1 py-1 text-base shadow-lg sm:text-sm',
                    customButton ? 'w-40' : 'w-full',
                    listBoxClassName,
                  )}
                  onClick={(e) => e.stopPropagation()}
                >
                  {search && (
                    <TextInput
                      autoComplete="off"
                      className="w-[97.5%] rounded-lg py-2.5 pl-8 pr-2 placeholder:font-normal placeholder:text-gray-600"
                      hideError
                      id="search"
                      icon={<SearchIcon className="stroke-charcoal ml-1 w-4" />}
                      endIcon={
                        searchTerm ? (
                          <button
                            type="button"
                            onClick={() => {
                              setFilteredOptions(options)
                              setSearchTerm('')
                              searchInputRef.current.value = ''
                            }}
                          >
                            <XMarkIcon className="h-5 text-blue-800" aria-hidden="true" />
                          </button>
                        ) : null
                      }
                      name="search"
                      onChange={(e) => {
                        setFilteredOptions(
                          _.filter(
                            options,
                            (o) =>
                              o.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
                              o.alwaysDisplay,
                          ),
                        )

                        setSearchTerm(e.target.value)
                      }}
                      placeholder="Search"
                      ref={searchInputRef}
                      type="text"
                      value={searchTerm}
                    />
                  )}

                  {filteredOptions.length === 0 ? (
                    <div className="flex justify-center py-2">
                      <span>{search ? 'No results found.' : 'No available options.'}</span>
                    </div>
                  ) : (
                    filteredOptions.map((o) => {
                      const selected = _.find(value, (v) => v.id === o.id)
                      return (
                        <ListboxOption
                          data-testid={`check:${o.id}`}
                          id={o.id}
                          key={o.id}
                          className="hover:bg-midnight group relative cursor-pointer select-none py-2 pl-3 pr-9 font-medium leading-5 tracking-[0.25px]"
                          value={o}
                        >
                          {() => (
                            <>
                              <span className="block truncate font-normal text-black group-hover:text-white">
                                {o.label}
                              </span>

                              {selected ? (
                                <span className="text-midnight absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </ListboxOption>
                      )
                    })
                  )}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

MultiSelectDropdown.defaultProps = {
  className: null,
  customButton: null,
  label: null,
  listBoxClassName: null,
  openAbove: false,
  openLeft: false,
  placeholder: 'Select an Option',
  search: false,
  style: {},
  value: [],
  containerClassName: null,
}

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  customButton: PropTypes.element,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  listBoxClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  openAbove: PropTypes.bool,
  openLeft: PropTypes.bool,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.array,
  containerClassName: PropTypes.string,
}

export default MultiSelectDropdown
