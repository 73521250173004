import { action, observable } from 'mobx'
import { createContext } from 'react'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  activeView: 'table',
}

const TYPES = {
  activeView: observable,
}

export class DisplayStore extends BaseStore {
  setActiveView = action((view) => {
    this.activeView = view
  })
}

export const display = new DisplayStore(INITIAL_STATE, TYPES)
export const DisplayStoreContext = createContext(display)
