import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import React from 'react'

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  // Pagination
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1
  const lastItemIndex = Math.min(firstItemIndex + itemsPerPage - 1, totalItems)
  const itemRangeText = `${firstItemIndex}-${lastItemIndex} of ${totalItems}`

  // Class names
  const buttonClassName = 'rounded-full p-2 disabled:cursor-not-allowed disabled:opacity-50'
  const iconClassName = 'text-charcoal-900 h-6 w-6'

  if (totalPages < 1) {
    return null
  }

  return (
    <div className="flex items-center justify-center gap-4">
      <span className="text-charcoal-900 whitespace-nowrap text-xs font-semibold uppercase">
        {itemRangeText}
      </span>
      <ul className="flex items-center">
        <li>
          <button
            aria-label="First Page"
            type="button"
            disabled={currentPage === 1}
            onClick={() => onPageChange(1)}
            className={buttonClassName}
          >
            <span className="text-charcoal flex">
              | <ChevronLeftIcon className={iconClassName} />
            </span>
          </button>
        </li>
        <li>
          <button
            aria-label="Previous Page"
            onClick={() => onPageChange(currentPage - 1)}
            className={buttonClassName}
            type="button"
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon className={iconClassName} />
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={buttonClassName}
          >
            <ChevronRightIcon className={iconClassName} />
          </button>
        </li>
        <li>
          <button
            type="button"
            onClick={() => onPageChange(totalPages)}
            className={buttonClassName}
            disabled={currentPage === totalPages}
          >
            <span className="text-charcoal flex">
              <ChevronRightIcon className={iconClassName} /> |
            </span>
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Pagination

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}
