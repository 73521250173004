import PropTypes from 'prop-types'
import React, { forwardRef, useState } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Components
import { PasswordVerifier } from '../PasswordVerifier'
import { Tooltip } from '../Tooltip'

// Icons
import EyeIcon from '../../assets/images/eye_icon.svg'
import EyeSlashIcon from '../../assets/images/eye_slash_icon.svg'

/**
 *
 * PasswordInput
 *
 */
const PasswordInput = forwardRef(
  (
    {
      className,
      error,
      fullWidth,
      icon,
      id,
      inputStyles,
      label,
      name,
      placeholder,
      renderIconStyles,
      value,
      onChange,
      showTooltip,
      ...rest
    },
    ref,
  ) => {
    // State
    const [reveal, setReveal] = useState(false)

    const renderToggle = () =>
      reveal ? (
        <img
          src={EyeIcon}
          alt="Hide password"
          className={mergeClassNames('h-4 w-4', renderIconStyles)}
        />
      ) : (
        <img
          src={EyeSlashIcon}
          alt="Show password"
          className={mergeClassNames('h-4 w-4', renderIconStyles)}
        />
      )

    return (
      <div className={`flex flex-col place-items-start ${fullWidth ? 'w-full' : ''}`}>
        <label
          htmlFor={id}
          className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
        >
          {label}
        </label>

        <Tooltip
          content={<PasswordVerifier password={value} />}
          fullWidth
          placement="bottom"
          display={showTooltip}
        >
          <div
            className={mergeClassNames(
              'border-1 bg-white-light relative flex w-full appearance-none rounded-md border-transparent px-3 py-2.5 pr-4',
              className,
            )}
          >
            {icon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1">
                {icon}
              </div>
            )}

            <input
              autoComplete="password"
              className={mergeClassNames(
                'placeholder:text-charcoal-400 relative w-full border-none bg-transparent p-0 text-base font-medium placeholder:text-base placeholder:font-normal focus:outline-none focus:ring-0 disabled:cursor-not-allowed',
                inputStyles,
              )}
              data-testid={name}
              id={id}
              name={name}
              placeholder={placeholder}
              ref={ref}
              required
              {...rest}
              value={value}
              type={reveal ? 'text' : 'password'}
              onChange={onChange}
            />

            <button
              data-testid="password-visibility-toggle"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 "
              onClick={(e) => {
                e.stopPropagation()

                setReveal(!reveal)
              }}
              type="button"
            >
              {renderToggle()}
            </button>
          </div>
        </Tooltip>
        <div
          className="mt-1 w-full bg-transparent px-2 py-1 text-center"
          aria-hidden={error ? 'false' : 'true'}
        >
          <p className="text-error min-h-[24px] text-sm font-medium" id={`error:${name}`}>
            {error || ' '}
          </p>
        </div>
      </div>
    )
  },
)

PasswordInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  inputStyles: PropTypes.string,
  endIcon: PropTypes.object,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  nunito: PropTypes.bool,
  placeholder: PropTypes.string,
  renderIconStyles: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  showTooltip: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

PasswordInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  inputStyles: '',
  endIcon: null,
  error: '',
  fullWidth: false,
  icon: null,
  id: '',
  label: '',
  name: '',
  nunito: false,
  placeholder: '',
  renderIconStyles: null,
  type: 'text',
  value: '',
  showTooltip: true,
}

export default PasswordInput
