import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Field, Label, Switch } from '@headlessui/react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

// Utils
import { joinClassNames } from '../../utils/helpers'

/**
 * Toggle Component
 * - Displays a toggle switch
 */
const Toggle = forwardRef(({ className, disabled, label, checked, onChange, ...rest }, ref) => (
  <Field as="div" className={mergeClassNames('flex items-center space-x-2', className)}>
    <Switch
      checked={Boolean(checked)}
      data-testid={`toggle:${label}`}
      disabled={disabled}
      onChange={onChange}
      className={joinClassNames(
        checked ? 'bg-blue-800' : 'bg-white',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out disabled:cursor-not-allowed disabled:opacity-50',
      )}
      ref={ref}
      {...rest}
    >
      <span
        className={joinClassNames(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      >
        <span
          className={joinClassNames(
            checked ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          )}
          aria-hidden="true"
        >
          <div className="bg-charcoal-900 h-full w-full rounded-full opacity-80" />
        </span>
        <span
          className={joinClassNames(
            checked ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center p-1 transition-opacity',
          )}
          aria-hidden="true"
        >
          <div className="h-full w-full rounded-full bg-white" />
        </span>
      </span>
    </Switch>

    <Label
      as="span"
      className={mergeClassNames(
        'text-xs font-bold uppercase leading-5 tracking-[3px] text-black',
      )}
      passive
    >
      {label}
    </Label>
  </Field>
))

Toggle.defaultProps = {
  checked: false,
  className: null,
  disabled: false,
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Toggle
