import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { CheckBox } from '../../components/CheckBox'
import { Modal } from '../../components/Modal'
import { Select } from '../../components/Select'
import { TextInput } from '../../components/TextInput'

// Utils & Service
import { getDealers } from '../../services/dealers.service'
import { addUser, updateUser } from '../../services/user.service'
import { toast } from '../../utils/helpers'

const ROLES = [
  { id: 'Service', label: 'Service' },
  { id: 'Management', label: 'Management' },
]

const DEFAULT_USER = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  dealerGroup: '',
  dealers: [],
  selectAll: null,
}

const DealerUserModal = ({ isOpen, setIsOpen, user = null, onSave, dealerGroupOptions }) => {
  // State
  const [loading, setLoading] = useState(false)
  const [dealerOptions, setDealerOptions] = useState([])

  const handleSuccess = (message) => {
    toast(message, 'success')
    reset()
    setIsOpen(false)
    onSave()
  }
  const handleError = (message) => toast(message, 'error')

  const selectedGroupId =
    user?.dealerUserProfile?.dealerGroups?.length > 0
      ? user.dealerUserProfile.dealerGroups[0].id
      : ''
  const selectedAllDealers = user?.dealerUserProfile?.dealers.length === 0 || false

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setError: setFormError,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({ defaultValues: DEFAULT_USER })

  // Function is triggered anytime the dealer group changes
  // Get the dealers associated with the selected dealer group.
  const fetchDealers = async (dealerGroup) => {
    if (!dealerGroup) return
    const response = await getDealers(
      `/dealers/?group=${dealerGroup}&limit=99999`,
      setLoading,
      handleError,
    )
    const options = response.results.map((dealer) => ({ ...dealer, label: dealer.name }))

    setDealerOptions(options)
  }

  // Initialize or update form values when modal opens or user data changes.
  useEffect(() => {
    if (!user) {
      reset(DEFAULT_USER)
      setDealerOptions([])
    } else {
      reset({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        email: user?.email || '',
        role: user?.dealerUserProfile?.role || '',
        dealerGroup: selectedGroupId,
        dealers: [],
        selectAll: selectedAllDealers,
      })

      fetchDealers(selectedGroupId)
    }
  }, [user])

  // - Initializes the 'dealers' field when editing a user, ensuring it reflects the current user's associated dealers.
  // - Runs when the `user` or `dealerOptions` change.
  // - Conditions checked:
  //   1. `user` must exist to confirm it's an edit operation.
  //   2. `dealerOptions` must be populated to provide selectable dealer data.
  //   3. `dealers` should be empty and `dealerGroup` should match the user's first dealer group to set dealers value.
  useEffect(() => {
    const dealers = getValues('dealers')
    const dealerGroup = getValues('dealerGroup')

    if (
      user &&
      dealerOptions.length > 0 &&
      dealers.length === 0 &&
      selectedGroupId === dealerGroup
    ) {
      let dealerIds = []

      if (user && user.dealerUserProfile) {
        dealerIds = user.dealerUserProfile.dealers.map((dealer) => dealer.id)
      }

      if (dealerIds.length === 0) {
        dealerIds = dealerOptions.map((option) => option.id)
      }

      setValue('dealers', dealerIds)
    }
  }, [user, dealerOptions])

  /**
   * Handles form submission
   * @param {Object} formData - The data entered in the form fields by the user.
   */
  const onSubmit = async (formData) => {
    if (formData.dealers.length === 0 && !formData.selectAll) {
      setFormError('dealers', {
        type: 'manual',
        message: 'Please select at least one dealer location.',
      })
      return
    }
    clearErrors('dealers')

    const { selectAll, dealers, firstName, lastName, email, role, dealerGroup } = formData

    if (!user) {
      const formattedData = {
        firstName,
        lastName,
        email,
        dealerUserProfile: {
          dealerGroups: [dealerGroup],
          dealers: selectAll ? [] : dealers,
          role,
        },
      }

      await addUser(formattedData, handleError, setLoading, handleSuccess)
    } else {
      const formattedData = {
        id: user.id,
        firstName,
        lastName,
        dealerUserProfile: {
          dealerGroups: [dealerGroup],
          dealers: selectAll ? [] : dealers,
          role,
        },
      }

      await updateUser(formattedData, handleError, setLoading, handleSuccess)
    }
  }

  const handleCheckboxChange = (event) => {
    const { checked, name, id } = event.target
    const dealers = getValues('dealers')
    clearErrors('dealers')

    if (id === 'selectAll') {
      if (checked) {
        setValue(
          'dealers',
          dealerOptions.map((option) => option.id),
          { shouldValidate: true },
        )
        setValue('selectAll', true, { shouldValidate: true })
      } else {
        setValue('dealers', [], { shouldValidate: true })
        setValue('selectAll', false, { shouldValidate: true })
      }
    } else {
      let newDealersList
      if (checked) {
        newDealersList = [...dealers, name]
      } else {
        newDealersList = dealers.filter((dealerId) => dealerId !== name)
      }
      setValue('dealers', newDealersList, { shouldValidate: true })

      if (newDealersList.length === dealerOptions.length) {
        setValue('selectAll', true, { shouldValidate: true })
      } else {
        setValue('selectAll', false, { shouldValidate: true })
      }
    }
  }

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      title={user ? 'Edit Dealer User' : 'Add Dealer User'}
      content={
        <div className="mb-2 mt-4">
          <TextInput
            data-testid="dealerUserFirstName"
            error={errors.firstName && 'This field is required'}
            label="First Name"
            placeholder="First Name"
            id="firstName"
            required
            fullWidth
            className="px-4 py-2.5 shadow-sm"
            inputStyles="text-charcoal-900 text-base font-medium"
            {...register('firstName', { required: true })}
          />
          <TextInput
            data-testid="dealerUserLastName"
            error={errors.lastName && 'This field is required'}
            label="Last Name"
            id="lastName"
            placeholder="Last Name"
            required
            fullWidth
            inputStyles="text-charcoal-900 text-base font-medium"
            className="px-4 py-2.5 shadow-sm"
            {...register('lastName', { required: true })}
          />
          <TextInput
            data-testid="dealerUserEmail"
            error={errors.email && errors.email.message}
            label="Email"
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            required
            fullWidth
            disabled={user}
            className="px-4 py-2.5 shadow-sm"
            inputStyles="text-charcoal-900 text-base font-medium"
            {...register('email', {
              required: 'This field is required',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            })}
          />
          <Controller
            name="role"
            control={control}
            rules={{ required: true }}
            render={({ field: { value }, fieldState }) => (
              <Select
                dataTestId="dealerUserRole"
                name="role"
                id="role"
                label="Role"
                value={ROLES.find((option) => option.id === value)}
                placeholder="Select an option"
                options={ROLES}
                className="bg-white-light rounded-md border-white"
                inputTextClassName="text-charcoal-900 text-base font-medium"
                shouldShowError
                error={fieldState.error && 'This field is required'}
                onChange={(option) => {
                  setValue('role', option.id, { shouldValidate: true })
                }}
              />
            )}
          />
          <Controller
            name="dealerGroup"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <Select
                dataTestId="dealerUserGroup"
                name="dealerGroup"
                id="dealerGroup"
                label="Dealer Group"
                value={dealerGroupOptions.find((option) => option.id === field.value)}
                placeholder="Select an option"
                options={dealerGroupOptions}
                className="bg-white-light rounded-md border-white"
                inputTextClassName="text-charcoal-900 text-base font-medium"
                shouldShowError
                error={fieldState.error && 'This field is required'}
                onChange={(option) => {
                  field.onChange(option.id)
                  fetchDealers(option.id)
                  setValue('dealers', [])
                  setValue('selectAll', false)
                }}
              />
            )}
          />
          {dealerOptions.length > 1 && (
            <CheckBox
              key="selectAll"
              label="Select All"
              name="selectAll"
              {...register('selectAll', {})}
              onChange={(event) => handleCheckboxChange(event, 'selectAll')}
              value={watch('selectAll')}
            />
          )}
          <div className="grid grid-cols-2 space-y-4">
            {dealerOptions.map((location) => (
              <CheckBox
                key={location.id}
                value={watch('dealers').includes(location.id)}
                label={location.label}
                name={location.id}
                onChange={(event) => handleCheckboxChange(event)}
              />
            ))}
          </div>
          <div
            className="mt-1 w-full bg-transparent px-2 py-1 text-center"
            aria-hidden={errors.dealers ? 'false' : 'true'}
          >
            <p className="text-error min-h-[24px] text-sm font-medium">
              {errors.dealers?.message || ' '}
            </p>
          </div>
        </div>
      }
      onClose={() => {
        setIsOpen(false)
        reset()
        setDealerOptions([])
      }}
      actions={[
        {
          type: 'cancel',
          label: 'Cancel',
          onClick: () => {
            setIsOpen(false)
            reset()
            setDealerOptions([])
          },
        },
        {
          type: 'submit',
          label: user ? 'Save' : 'Invite User',
          onClick: handleSubmit(onSubmit),
        },
      ]}
      loading={loading}
    />
  )
}

export default DealerUserModal

DealerUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    role: PropTypes.string,
    dealerGroup: PropTypes.string,
    dealers: PropTypes.array,
  }),
  onSave: PropTypes.func.isRequired,
  dealerGroupOptions: PropTypes.array.isRequired,
}
