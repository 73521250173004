/* eslint-disable react/no-unstable-nested-components */
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

// Components
import DatePicker from '../CustomDatePicker'

// Images
import Calendar from '../../assets/images/calendar.svg'

/**
 *
 * CustomDatePicker
 *
 */
const CustomDatePicker = ({
  dataTestId,
  disabled,
  error,
  format,
  label,
  name,
  onChange,
  show,
  setShow,
  value,
}) => {
  // State
  const [pickerValue, setPickerValue] = useState(value ? new Date(value) : new Date())
  const [dayInputValue, setDayInputValue] = useState(
    value ? dayjs(value, 'MM/DD/YYYY').format('DD') : dayjs().format('DD'),
  )
  const [monthInputValue, setMonthInputValue] = useState(
    value ? dayjs(value, 'MM/DD/YYYY').format('MM') : dayjs().format('MM'),
  )
  const [yearInputValue, setYearInputValue] = useState(
    value ? dayjs(value, 'MM/DD/YYYY').format('YYYY') : dayjs().format('YYYY'),
  )

  // Refs
  const datePickerRef = useRef()
  const monthInputRef = useRef()
  const dayInputRef = useRef()
  const yearInputRef = useRef()

  useEffect(() => {
    // On first render, set the value to the current date
    onChange(dayjs(`${monthInputValue}/${dayInputValue}/${yearInputValue}`).format(format))
  }, [name])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dayInputRef?.current &&
        !monthInputRef?.current &&
        !yearInputRef?.current &&
        !datePickerRef?.current
      )
        return
      if (
        !dayInputRef.current.contains(event.target) &&
        !monthInputRef.current.contains(event.target) &&
        !yearInputRef.current.contains(event.target) &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShow(false)
      }
    }

    const handleKeyDown = (event) => {
      // Check for both `Escape` and `keyCode` 27 to ensure compatibility
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        setShow(false)
      }
    }

    document.addEventListener('mousedown', (event) => handleClickOutside(event))
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event))
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [datePickerRef, dayInputRef, monthInputRef, yearInputRef, setShow])

  return (
    <div className="flex w-full flex-col place-items-start">
      {label && (
        <label
          htmlFor={name}
          className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
        >
          {label}
        </label>
      )}

      <div className="w-full" ref={datePickerRef}>
        <DatePicker
          disabled={disabled}
          options={{
            autoHide: true,
            todayBtn: true,
            clearBtn: false,
            maxDate: new Date('2030-01-01'),
            minDate: new Date('1950-01-01'),
            theme: {
              background: 'bg-background',
              todayBtn: 'bg-blue-800 border-blue-800 hover:bg-blue-900 px-2 py-1.5',
              clearBtn: '',
              icons:
                'bg-background hover:bg-blue-900 text-blue-800 hover:text-white sm:text-sm text-xs group px-2 py-1.5',
              text: 'text-black hover:text-blue-900',
              disabledText: 'px-1 py-0 opacity-50 cursor-not-allowed',
              input: '',
              inputIcon: '',
              selected: 'text-white bg-blue-800 hover:bg-blue-900 px-1 py-0',
            },
            icons: {
              prev: () => (
                <ArrowLeftIcon className="h-3 stroke-blue-800 group-hover:stroke-white sm:h-4" />
              ),
              next: () => (
                <ArrowRightIcon className="h-3 stroke-blue-800 group-hover:stroke-white sm:h-4" />
              ),
            },
            datepickerClassNames: 'top-12',
            language: 'en',
            inputPlaceholderProp: 'Select a Date',
            inputIdProp: name,
          }}
          onChange={(date) => {
            setPickerValue(date)

            const updatedDate = dayjs(date)

            setDayInputValue(updatedDate.format('DD'))
            setMonthInputValue(updatedDate.format('MM'))
            setYearInputValue(updatedDate.format('YYYY'))

            onChange(updatedDate.format(format))
          }}
          ref={datePickerRef}
          show={show}
          setShow={!disabled && setShow}
          value={pickerValue}
        >
          <div className="bg-white-light relative flex w-full appearance-none rounded-md border-[1px] px-3 py-2.5 pr-4">
            <input
              type="text"
              className="placeholder:text-charcoal-400 disabled:text-charcoal-400 w-8 border-none bg-transparent p-0 text-base font-medium placeholder:text-base placeholder:font-normal focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
              disabled={disabled}
              value={monthInputValue}
              maxLength={2}
              onChange={(e) => {
                setMonthInputValue(e.target.value)

                // If the user types 2 characters, focus on the day input
                if (e.target.value.length === 2) {
                  setTimeout(() => dayInputRef.current.focus(), 500)
                }
              }}
              onBlur={() => {
                let updatedValue = monthInputValue

                const dayPickerValue = dayjs(pickerValue).format('DD')
                const yearPickerValue = dayjs(pickerValue).format('YYYY')

                if (!updatedValue) {
                  updatedValue = dayjs(pickerValue).format('MM')
                  setMonthInputValue(updatedValue)
                }

                const date = dayjs(`${updatedValue}/${dayPickerValue}/${yearPickerValue}`, format)
                if (date.isValid()) {
                  setPickerValue(date.toDate())
                  onChange(date.format(format))
                } // If date is invalid, reset back to picker value
                else {
                  setMonthInputValue(dayjs(pickerValue).format('MM'))
                  setPickerValue(pickerValue)
                  onChange(dayjs(pickerValue).format(format))
                }
              }}
              onFocus={() => setShow(true)}
              placeholder="MM"
              ref={monthInputRef}
              data-testid={`${dataTestId}:month`}
            />

            <span className="text-charcoal-400">/</span>

            <input
              type="text"
              className="placeholder:text-charcoal-400 disabled:text-charcoal-400 ml-1 w-7 border-none bg-transparent p-0 text-base font-medium placeholder:text-base placeholder:font-normal focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
              disabled={disabled}
              value={dayInputValue}
              maxLength={2}
              onChange={(e) => {
                setDayInputValue(e.target.value)

                // If the user types 2 characters, focus on the year input
                if (e.target.value.length === 2) {
                  setTimeout(() => yearInputRef.current.focus(), 500)
                }
              }}
              onBlur={() => {
                let updatedValue = dayInputValue

                const monthPickerValue = dayjs(pickerValue).format('MM')
                const yearPickerValue = dayjs(pickerValue).format('YYYY')

                if (!updatedValue) {
                  updatedValue = dayjs(pickerValue).format('DD')
                  setDayInputValue(updatedValue)
                }

                const date = dayjs(
                  `${monthPickerValue}/${updatedValue}/${yearPickerValue}`,
                  format,
                )
                if (date.isValid()) {
                  setPickerValue(date.toDate())
                  onChange(date.format(format))
                } // If date is invalid, reset back to picker value
                else {
                  setDayInputValue(dayjs(pickerValue).format('DD'))
                  setPickerValue(pickerValue)
                  onChange(dayjs(pickerValue).format(format))
                }
              }}
              onFocus={() => setShow(true)}
              placeholder="DD"
              ref={dayInputRef}
              data-testid={`${dataTestId}:day`}
            />

            <span className="text-charcoal-400">/</span>

            <input
              type="text"
              className="placeholder:text-charcoal-400 disabled:text-charcoal-400 ml-1 w-full border-none bg-transparent p-0 text-base font-medium placeholder:text-base placeholder:font-normal focus:outline-none focus:ring-0 disabled:cursor-not-allowed"
              disabled={disabled}
              value={yearInputValue}
              maxLength={4}
              onChange={(e) => setYearInputValue(e.target.value)}
              onBlur={() => {
                let updatedValue = yearInputValue

                const dayPickerValue = dayjs(pickerValue).format('DD')
                const monthPickerValue = dayjs(pickerValue).format('MM')

                // If no year supplied, set to current year
                if (!updatedValue || updatedValue.length < 4) {
                  updatedValue = dayjs().format('YYYY')
                  setYearInputValue(updatedValue)
                }

                const date = dayjs(`${monthPickerValue}/${dayPickerValue}/${updatedValue}`, format)
                if (date.isValid()) {
                  setPickerValue(date.toDate())
                  onChange(date.format(format))
                } // If date is invalid, reset back to picker value
                else {
                  setYearInputValue(dayjs(pickerValue).format('YYYY'))
                  setPickerValue(pickerValue)
                  onChange(dayjs(pickerValue).format(format))
                }
              }}
              onFocus={() => setShow(true)}
              placeholder="YYYY"
              ref={yearInputRef}
              data-testid={`${dataTestId}:year`}
            />

            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <img alt="Calendar" className="ml-1.5 h-5" src={Calendar} />
            </div>
          </div>
        </DatePicker>
      </div>

      <div
        className="mt-1 w-full bg-transparent px-2 py-1 text-center"
        aria-hidden={error ? 'false' : 'true'}
      >
        <p className="text-error min-h-[24px] text-sm font-medium" id={`error:${name}`}>
          {error?.message || ' '}
        </p>
      </div>
    </div>
  )
}

CustomDatePicker.defaultProps = {
  dataTestId: null,
  disabled: false,
  error: null,
  format: 'MM/DD/YYYY',
  label: null,
  value: '',
}

CustomDatePicker.propTypes = {
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  format: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default CustomDatePicker
