// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the list of Dealers.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getDealers = async (url, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the KPIs using the specified `url`.
 * @param {string} url
 * @param {func} setLoading
 * @param {func} setError
 * @returns `data` results
 */
export const getDealerKpis = async (url, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the list of filter options using the specified `url`.
 * @param {string} url
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getFilterListOptions = async (url, setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(url)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Gets the list of dealer groups
 * @param {func} setError
 * @param {func} setLoading
 * @returns `data` results
 */
export const getDealerGroups = async (setLoading = () => {}, setError = () => {}) => {
  setLoading(true)

  try {
    const data = await axios.get(`/dealers/dealer-groups/`)

    setLoading(false)
    return data
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
