/**
 * PublicRoute
 *
 * If a user is authenticated and attempts to access the component, they will be
 * redirected to their portfolio
 */

import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { observer } from 'mobx-react'

// Store
import { UserStoreContext } from '../../stores/UserStore'

const PublicRoute = observer(({ component: Component }) => {
  // Context
  const { accessToken } = useContext(UserStoreContext)

  return accessToken ? (
    <Navigate
      to={{
        pathname: '/dashboard',
      }}
    />
  ) : (
    <Component />
  )
})

export default PublicRoute
