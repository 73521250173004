import React from 'react'
import PropTypes from 'prop-types'

const MinusIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14 8L2 8"
      stroke="inherit"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

MinusIcon.defaultProps = {
  className: '',
}

MinusIcon.propTypes = {
  className: PropTypes.string,
}

export default MinusIcon
