import { Textarea as TextareaField } from '@headlessui/react'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { twMerge as mergeClassNames } from 'tailwind-merge'

/**
 * Text Area
 */
const Textarea = forwardRef(
  (
    {
      autoComplete,
      className,
      containerClassName,
      error,
      fullWidth,
      hideError,
      textareaStyles,
      icon,
      id,
      label,
      name,
      placeholder,
      ...rest
    },
    ref,
  ) => (
    <div className="my-4 flex flex-wrap">
      <label
        htmlFor={id}
        className="text-charcoal pb-1 text-xs font-semibold uppercase leading-[14px] tracking-[1.5px]"
      >
        {label}
      </label>

      <TextareaField
        id={id}
        name={name}
        placeholder={placeholder}
        className={mergeClassNames(
          'placeholder:text-charcoal-400 border-white-light bg-white-light min-h-[75px] w-full rounded-lg p-3 text-base text-base font-medium',
          textareaStyles,
        )}
        {...rest}
        ref={ref}
      />

      {!hideError && (
        <div
          className="mt-1 w-full bg-transparent px-2 py-1 text-center"
          aria-hidden={error ? 'false' : 'true'}
        >
          <p className="text-error min-h-[24px] text-sm font-medium" id={`error:${name}`}>
            {error || ' '}
          </p>
        </div>
      )}
    </div>
  ),
)

Textarea.defaultProps = {
  autoComplete: 'off',
  className: '',
  containerClassName: null,
  error: '',
  fullWidth: false,
  hideError: false,
  icon: null,
  id: '',
  label: '',
  name: '',
  placeholder: '',
  textareaStyles: '',
}

Textarea.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideError: PropTypes.bool,
  icon: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  textareaStyles: PropTypes.string,
}

export default Textarea
