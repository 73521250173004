import React from 'react'
import PropTypes from 'prop-types'

const ActionMoreIcon = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99924 7.75008C7.86124 7.75008 7.74924 7.86208 7.75058 8.00008C7.75058 8.13808 7.86258 8.25008 8.00058 8.25008C8.13858 8.25008 8.25058 8.13808 8.25058 8.00008C8.24991 7.86208 8.13791 7.75008 7.99924 7.75008"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.666 7.75008C10.528 7.75008 10.416 7.86208 10.4173 8.00008C10.4173 8.13808 10.5293 8.25008 10.6673 8.25008C10.8053 8.25008 10.9173 8.13808 10.9173 8.00008C10.9167 7.86208 10.8047 7.75008 10.666 7.75008"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33274 7.75008C5.19474 7.75008 5.08274 7.86208 5.08407 8.00008C5.08407 8.13808 5.19607 8.25008 5.33407 8.25008C5.47207 8.25008 5.58407 8.13808 5.58407 8.00008C5.5834 7.86208 5.47141 7.75008 5.33274 7.75008"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ActionMoreIcon.defaultProps = {
  className: '',
}

ActionMoreIcon.propTypes = {
  className: PropTypes.string,
}

export default ActionMoreIcon
