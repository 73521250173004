import React from 'react'
import ReactDOM from 'react-dom/client'

// Local
import trunk from './configureStore'
import App from './App'
import './index.css'

const root = document.getElementById('root')
trunk.init().then(() => ReactDOM.createRoot(root).render(<App />))
