import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { Modal } from '../../components/Modal'
import { TextInput } from '../../components/TextInput'
import { TrashIcon } from '../../components/TrashIcon'

// Utils & Services
import { updateRepairOrder } from '../../services/repairOrders.service'
import { toast } from '../../utils/helpers'

/**
 *
 * ReopenRepairOrderModal
 *
 */
const ReopenRepairOrderModal = ({
  isOpen,
  setIsOpen,
  repairOrderId,
  dealerId,
  lineItems,
  getUpdatedRepairOrder,
}) => {
  const handleSuccess = (message) => toast(message, 'success')
  const handleError = (message) => toast(message, 'error')

  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      markAsUnresolved: true,
      reopenLineItems: [],
      newLineItem: { lineName: '', claimAmount: '' },
    },
  })

  // State
  const [loading, setLoading] = useState(false)
  const [isAddingNewLineItem, setIsAddingNewLineItem] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // Watch for changes in the form fields
  const selectedCheckboxes = watch('reopenLineItems', [])
  const newLineItemName = watch('newLineItem.lineName', '')
  const newLineItemAmount = watch('newLineItem.claimAmount', '')

  // Clear error message when any field is interacted with
  useEffect(() => {
    if (errorMessage && (selectedCheckboxes.length > 0 || newLineItemName || newLineItemAmount)) {
      setErrorMessage('')
    }
  }, [selectedCheckboxes, newLineItemName, newLineItemAmount, errorMessage])

  const onSubmit = async (data) => {
    const payload = { ...data }

    const hasSelectedLineItems = data.reopenLineItems.length > 0
    const hasNewLineItem = data.newLineItem?.lineName && data.newLineItem?.claimAmount
    /**
     * Show an error message to the user if they haven't selected
     * any line items or added a new line item
     */
    if (!hasSelectedLineItems && !hasNewLineItem) {
      setErrorMessage(
        'Please select a line item or add a new line item in order to reopen repair order.',
      )
      return
    }

    setErrorMessage('')

    // Check if new line item is being added
    if (!isAddingNewLineItem) {
      delete payload.newLineItem
    }

    const response = await updateRepairOrder(
      dealerId,
      repairOrderId,
      payload,
      setLoading,
      handleError,
      () => {
        handleSuccess(`Repair order has been reopened.`)
      },
    )
    if (response) {
      setIsOpen(false)
      getUpdatedRepairOrder()
    }
  }

  const onCloseModal = () => {
    setIsOpen(false)
    reset()
    setErrorMessage('')
    setIsAddingNewLineItem(false)
  }

  return (
    <Modal
      open={isOpen}
      title="Reopen Repair Order"
      setOpen={setIsOpen}
      loading={loading}
      onClose={onCloseModal}
      content={
        <div className="mt-6">
          <div className="mb-4 min-h-[1.5rem] text-red-600" role="alert" aria-live="polite">
            {errorMessage && (
              <span className="text-error text-sm font-medium">{errorMessage}</span>
            )}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="text-charcoal-900 mb-4 text-sm font-semibold uppercase leading-3">
              Which Line Item(s) Would You Like to Reopen?
            </p>
            <div className="flex h-full">
              {lineItems.length === 0 ? (
                <p>No Line Items to Reopen.</p>
              ) : (
                <section className="grid max-h-[250px] grid-cols-1 gap-4 overflow-y-scroll py-1 pl-4 sm:grid-cols-2">
                  {lineItems.map((lineItem) => (
                    <div key={lineItem.id}>
                      <Controller
                        name="reopenLineItems"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <CheckBox
                            value={lineItem.id}
                            checked={value.includes(lineItem.id)}
                            name={`lineItem-${lineItem.id}`}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, lineItem.id]
                                : value.filter((id) => id !== lineItem.id)
                              onChange(newValue)
                            }}
                            label={`Line Item ${lineItem.lineName}`}
                          />
                        )}
                      />
                    </div>
                  ))}
                </section>
              )}
            </div>
            {!isAddingNewLineItem && (
              <Button
                type="button"
                label="Add New Line Item"
                background="bg-background"
                size="xs"
                className="mt-6"
                onClick={() => setIsAddingNewLineItem(true)}
              />
            )}
            {isAddingNewLineItem && (
              <section className="mb-4 mt-8">
                <p className="text-charcoal-900 mb-4 text-sm font-semibold uppercase leading-4">
                  New Line Item
                </p>
                <div className="flex w-full flex-col items-center gap-4 sm:flex-row">
                  <TextInput
                    data-testid="newLineItemName"
                    error={errors.newLineItem?.lineName && errors.newLineItem.lineName.message}
                    label="Line ID"
                    id="lineName"
                    name="lineName"
                    placeholder=""
                    required
                    fullWidth
                    inputStyles="uppercase"
                    {...register('newLineItem.lineName', { required: 'This field is required' })}
                  />

                  <Controller
                    name="newLineItem.claimAmount"
                    control={control}
                    rules={{
                      required: 'This field is required',
                    }}
                    defaultValue=""
                    render={({ field: { value, onChange, ...field } }) => (
                      <TextInput
                        data-testid="newLineItemClaimAmount"
                        error={
                          errors.newLineItem?.claimAmount && errors.newLineItem.claimAmount.message
                        }
                        label="Amount"
                        id="claimAmount"
                        placeholder=""
                        fullWidth
                        required
                        currency
                        prefix="$"
                        className="text-base"
                        {...field}
                        value={value}
                        onChange={(e) => {
                          let rawValue = e.target.value

                          const decimalCount = (rawValue.match(/\./g) || []).length
                          const hasMultipleDecimals = decimalCount > 1

                          if (hasMultipleDecimals) {
                            setError('claimAmount', {
                              type: 'custom',
                              message: 'Cannot have multiple decimals',
                            })
                            // Return without updating the value to prevent adding a second decimal
                            return
                          }
                          clearErrors('claimAmount')

                          // Remove non-numeric characters except for the decimal point
                          rawValue = rawValue.replace(/[^0-9.]/g, '')

                          // Do not automatically format to two decimal places unless user inputs them
                          // Only convert to a number if the input is a valid number without unnecessary formatting
                          const numericValue = parseFloat(rawValue)

                          if (!Number.isNaN(numericValue) && rawValue.includes('.')) {
                            // Preserve user input without forcing two decimal places unless they input them
                            const decimalIndex = rawValue.indexOf('.')
                            const decimalPart = rawValue.substring(decimalIndex + 1)

                            if (decimalPart.length > 2) {
                              // If more than two decimal places are entered, truncate to two decimal places
                              rawValue = rawValue.substring(0, decimalIndex + 3)
                            }
                          }

                          // Update the form state with the raw value without moving the cursor
                          onChange(rawValue)
                        }}
                      />
                    )}
                  />
                  <button
                    aria-label="Delete"
                    type="button"
                    onClick={() => {
                      reset({ lineName: '', claimAmount: '' })
                      setIsAddingNewLineItem(false)
                    }}
                    className="mb-5 rounded-lg bg-transparent text-sm font-medium text-gray-700 hover:bg-gray-100 disabled:cursor-not-allowed"
                  >
                    <TrashIcon className="text-charcoal-800 h-6 w-6" />
                  </button>
                </div>
              </section>
            )}
          </form>
        </div>
      }
      actions={[
        {
          type: 'submit',
          label: 'Reopen',
          onClick: handleSubmit(async (data) => {
            onSubmit(data)
          }),
        },
        { type: 'cancel', label: 'Cancel', onClick: onCloseModal },
      ]}
    />
  )
}

export default ReopenRepairOrderModal

ReopenRepairOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  repairOrder: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired,
  repairOrderId: PropTypes.string.isRequired,
  dealerId: PropTypes.string.isRequired,
  getUpdatedRepairOrder: PropTypes.func.isRequired,
}
