import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Button } from '../../components/Button'
// import { FileUploader } from '../../components/FileUploader'
import { PasswordInput } from '../../components/PasswordInput'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Stores
import { UserStoreContext } from '../../stores/UserStore'

// Utils & Service
import {
  confirmEmailChange,
  requestEmailChange,
  updateUser,
  updateUserPassword,
} from '../../services/user.service'
import { toast, verifyPassword } from '../../utils/helpers'

/**
 *
 * UserProfile
 *
 */
const UserProfile = observer(() => {
  // Context
  const {
    error: loadingError,
    loading,
    getUpdatedUser,
    setCurrentUser,
    user,
    isDealerUser,
  } = useContext(UserStoreContext)

  // State
  // const [uploadLogo, setUploadLogo] = useState(false)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [loadingPassword, setLoadingPassword] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [verifyMfa, setVerifyMfa] = useState(null)

  useEffect(() => {
    getUpdatedUser(user.id)
  }, [])

  const {
    handleSubmit: handleProfileSubmit,
    formState: { errors: profileErrors, isDirty: profileIsDirty },
    register: profileRegister,
    reset: profileReset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  })

  const {
    control: passwordControl,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors, isDirty: passwordIsDirty },
    reset: resetPasswordForm,
    watch,
  } = useForm({
    defaultValues: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
  })

  const {
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailErrors, isDirty: emailIsDirty },
    getValues: getEmailValues,
    register: emailRegister,
    reset: emailReset,
  } = useForm({
    defaultValues: {
      email: '',
      code: '',
    },
  })

  useEffect(() => {
    if (user) {
      profileReset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      })

      emailReset({
        email: user.email,
      })
    }
  }, [user])

  const handleSuccesses = (message) => toast(message, 'success')
  const handleErrors = (message) => toast(message, 'error')

  /**
   * Handles submitting changes to the user's profile.
   * - If the user is changing either their email or phone number, these get handled
   *  separately. Both of these changes require a verification code.
   * @param {object} data
   */
  const onProfileSubmit = async (data) =>
    updateUser(
      {
        id: user.id,
        ...data,
      },
      handleErrors,
      setLoadingProfile,
      (m) => handleSuccesses(m),
      setCurrentUser,
    )

  /**
   * Handles requesting a change to the user's email address, and sending the verification
   * code.
   * @param {object} data
   */
  const onEmailSubmit = async (data) => {
    if (verifyMfa === 'email') {
      await confirmEmailChange(
        data.code,
        handleErrors,
        setLoadingEmail,
        (m) => {
          handleSuccesses(m)
          setVerifyMfa(null)
        },
        setCurrentUser,
      )
    } else {
      await requestEmailChange(data.email, handleErrors, setLoadingEmail, (m) => {
        handleSuccesses(m)
        setVerifyMfa('email')
      })
    }
  }

  // const renderUserAvatar = () => {
  //   if (uploadLogo) {
  //     return (
  //       <div>
  //         <FileUploader
  //           acceptedFileTypes={['image/*']}
  //           allowResize
  //           allowRevert
  //           handleUploadToServer={async (file) => {
  //             const update = await updateUser(
  //               {
  //                 id: user.id,
  //                 profileImageUrl: file.url,
  //               },
  //               handleErrors,
  //               () => {},
  //               () => handleSuccesses('Profile image updated.'),
  //             )

  //             setCurrentUser(update)
  //             setUploadLogo(null)
  //           }}
  //           id="profileImageUrl"
  //           imageCropAspectRatio="1:1"
  //           type="gcp"
  //         />

  //         <div className="-mt-4 mr-2 grid justify-items-end">
  //           <button className="place-self-end" type="button" onClick={() => setUploadLogo(null)}>
  //             <span className="text-xs">Cancel Upload</span>
  //           </button>
  //         </div>
  //       </div>
  //     )
  //   }

  //   return (
  //     <div className=" flex items-center gap-x-4">
  //       {/* <img
  //         src={user.signedProfileImageUrl || UserPlaceholder}
  //         alt="User"
  //         className="h-24 w-24 flex-none rounded-full object-cover"
  //       /> */}

  //       <Button
  //         background="bg-white"
  //         label="Change"
  //         onClick={() => setUploadLogo(true)}
  //         outlined
  //       />
  //     </div>
  //   )
  // }

  return (
    <div className="bg-background flex h-full w-full flex-col overflow-y-auto px-4 pb-12 pt-6 sm:px-6 lg:px-8">
      <StateContainer error={loadingError} loading={loading}>
        <div className="flex flex-col space-y-8 px-10 md:w-[75%] md:min-w-[650px] md:max-w-5xl md:px-10">
          <div className="bg-brownGray-100 grid grid-cols-1 gap-y-4 rounded-lg px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">
                Personal Information
              </h2>
              {isDealerUser && (
                <p className="text-primary mt-1 text-sm leading-6">
                  Update any profile information.
                </p>
              )}
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-2 gap-4">
                {/* <div className="col-span-full">{renderUserAvatar()}</div> */}

                <div className="col-span-1">
                  <TextInput
                    data-testid="firstName"
                    error={profileErrors.firstName && 'This field is required'}
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    placeholder="First Name"
                    disabled={!isDealerUser}
                    {...profileRegister('firstName', { required: true })}
                  />
                </div>

                <div className="col-span-1">
                  <TextInput
                    data-testid="lastName"
                    error={profileErrors.lastName && 'This field is required'}
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    disabled={!isDealerUser}
                    {...profileRegister('lastName', { required: true })}
                  />
                </div>
              </div>
              {isDealerUser && (
                <div className="flex flex-col place-items-end space-y-2">
                  <Button
                    disabled={loadingProfile || !profileIsDirty}
                    onClick={handleProfileSubmit(onProfileSubmit)}
                    label="Save Changes"
                    loading={loadingProfile}
                    type="button"
                    size="sm"
                  />
                </div>
              )}
            </form>
          </div>

          {isDealerUser && (
            <div className="bg-brownGray-100 grid grid-cols-1 gap-x-8 gap-y-4 rounded-lg px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
              <div className="col-span-full sm:col-span-2">
                <h2 className="text-primary text-base font-semibold leading-7">Change password</h2>
                <p className="text-primary mt-1 text-sm leading-6">
                  Update the password associated with your account.
                </p>
              </div>

              <form className="col-span-full sm:col-span-3">
                <div className="grid grid-cols-2 text-center">
                  <div className="col-span-full">
                    <Controller
                      name="oldPassword"
                      control={passwordControl}
                      render={({ field: { onChange, value } }) => (
                        <PasswordInput
                          autoComplete="password"
                          data-testid="oldPassword"
                          error={passwordErrors.oldPassword && 'This field is required'}
                          fullWidth
                          id="oldPassword"
                          label="Current Password"
                          placeholder="Current Password"
                          onChange={onChange}
                          value={value}
                          showTooltip={false}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>

                  <div className="col-span-full">
                    <Controller
                      name="newPassword"
                      control={passwordControl}
                      render={({ field: { onChange, value } }) => (
                        <PasswordInput
                          autoComplete="password"
                          data-testid="newPassword"
                          error={
                            passwordErrors.newPassword &&
                            (passwordErrors.newPassword.message || 'This field is required')
                          }
                          fullWidth
                          id="newPassword"
                          name="newPassword"
                          label="New Password"
                          placeholder="New Password"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      rules={{ required: true, validate: (value) => verifyPassword(value) }}
                    />
                  </div>

                  <div className="col-span-full">
                    <Controller
                      name="confirmPassword"
                      control={passwordControl}
                      render={({ field: { onChange, value } }) => (
                        <PasswordInput
                          autoComplete="password"
                          data-testid="confirmPassword"
                          error={
                            passwordErrors.confirmPassword &&
                            (passwordErrors.confirmPassword.message || 'This field is required')
                          }
                          fullWidth
                          id="confirmPassword"
                          name="confirmPassword"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      rules={{
                        required: true,
                        validate: (value) => {
                          const err = verifyPassword(value)
                          if (err) return err
                          if (watch('newPassword') !== value) {
                            return 'Passwords do not match'
                          }
                          return undefined
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="mt-4 flex flex-col place-items-end space-y-2">
                  <Button
                    disabled={loadingPassword || !passwordIsDirty}
                    onClick={handlePasswordSubmit((data) =>
                      updateUserPassword(
                        {
                          id: user.id,
                          ...data,
                        },
                        handleErrors,
                        setLoadingPassword,
                        (m) => {
                          handleSuccesses(m)
                          resetPasswordForm()
                        },
                      ),
                    )}
                    label="Save Changes"
                    loading={loadingPassword}
                    type="button"
                    size="sm"
                  />
                </div>
              </form>
            </div>
          )}

          <div className="bg-brownGray-100 grid grid-cols-1 gap-x-8 gap-y-4 rounded-lg px-4 py-8 shadow-md sm:grid-cols-5 sm:gap-y-0">
            <div className="col-span-full sm:col-span-2">
              <h2 className="text-primary text-base font-semibold leading-7">Email Address</h2>
              {isDealerUser && (
                <p className="text-primary mt-1 text-sm leading-6">
                  Update the email address associated with your account.
                </p>
              )}
            </div>

            <form className="col-span-full sm:col-span-3">
              <div className="grid grid-cols-2">
                <div className="col-span-full">
                  <TextInput
                    data-testid="email"
                    error={emailErrors.email && 'This field is required'}
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    disabled={!isDealerUser}
                    {...emailRegister('email', { required: true })}
                  />
                </div>

                {verifyMfa === 'email' && (
                  <div className="col-span-full flex flex-col">
                    <TextInput
                      data-testid="code"
                      error={profileErrors.code && 'This field is required'}
                      fullWidth
                      id="code"
                      name="code"
                      label="Verification Code"
                      placeholder="Verification Code"
                      {...emailRegister('code', { required: true })}
                    />

                    <button
                      className="mr-1 place-self-end"
                      type="button"
                      onClick={async () => {
                        await requestEmailChange(
                          getEmailValues().email,
                          handleErrors,
                          setLoadingEmail,
                          (m) => {
                            handleSuccesses(m)
                            setVerifyMfa('email')
                          },
                        )
                      }}
                    >
                      <span className="text-sm font-medium text-blue-500 hover:text-blue-600">
                        Resend Verification Code
                      </span>
                    </button>
                  </div>
                )}
              </div>

              <div className="flex flex-row justify-end space-x-2">
                {verifyMfa === 'email' && (
                  <Button
                    background="bg-white"
                    loading={loading}
                    onClick={() => {
                      setVerifyMfa(null)
                      emailReset()
                    }}
                    outlined
                    label="Cancel"
                    size="sm"
                  />
                )}
                {isDealerUser && (
                  <Button
                    disabled={loadingEmail || !emailIsDirty}
                    onClick={handleEmailSubmit(onEmailSubmit)}
                    label={verifyMfa === 'email' ? 'Confirm Change' : 'Save Changes'}
                    loading={loadingEmail}
                    type="button"
                    size="sm"
                  />
                )}
              </div>
            </form>
          </div>
          <div className="h-[10px] flex-none" aria-hidden="true" />
        </div>
      </StateContainer>
    </div>
  )
})

export default UserProfile
