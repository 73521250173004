import React, { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import PropTypes from 'prop-types'

import { addDays } from '../../Utils/date'
import { DatePickerContext } from '../DatePickerProvider'

const Days = ({ start }) => {
  const { selectedDate, changeSelectedDate, showSelectedDate, getFormattedDate, options } =
    useContext(DatePickerContext)

  const startOfWeek = (new Date(start).getDay() + 6) % 7
  const weekDays = options.weekDays || []
  const sortedWeekDays = weekDays.slice(startOfWeek).concat(weekDays.slice(0, startOfWeek))

  return (
    <>
      <div className="mb-1 grid grid-cols-7">
        {sortedWeekDays.map((day) => (
          <span
            key={`daylabel-${day}`}
            className="dow h-6 text-center text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
          >
            {day}
          </span>
        ))}
      </div>
      <div className="grid w-64 grid-cols-7">
        {[...Array(42)].map((_date, index) => {
          const current = addDays(start, index)
          const day = getFormattedDate(current, { day: 'numeric' })
          const month = getFormattedDate(current, { month: 'long' })
          const year = getFormattedDate(current, { year: 'numeric' })
          return (
            <button
              key={`month-${month}day-${day}`}
              className={`block flex-1 cursor-pointer rounded-lg border-0 text-center text-sm font-semibold leading-9  hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600 ${
                showSelectedDate &&
                selectedDate.getTime() > 0 &&
                getFormattedDate(selectedDate) === getFormattedDate(current)
                  ? twMerge('bg-blue-700 text-white hover:bg-blue-600', options?.theme?.selected)
                  : ''
              } ${
                month === getFormattedDate(selectedDate, { month: 'long' }) &&
                year === getFormattedDate(selectedDate, { year: 'numeric' })
                  ? twMerge('text-gray-900', options?.theme?.text)
                  : twMerge('text-gray-500', options?.theme?.disabledText)
              } ${
                (options?.minDate && new Date(current) < options?.minDate) ||
                (options?.disabledDates && options.disabledDates.indexOf(new Date(current)) >= 0)
                  ? twMerge('text-gray-500', options?.theme?.disabledText)
                  : ''
              } ${
                (options?.maxDate && new Date(current) > options?.maxDate) ||
                (options?.disabledDates && options.disabledDates.indexOf(new Date(current)) >= 0)
                  ? twMerge('text-gray-500', options?.theme?.disabledText)
                  : ''
              }
                            `}
              onClick={() => {
                changeSelectedDate('date', new Date(current))
              }}
              type="button"
            >
              {day}
            </button>
          )
        })}
      </div>
    </>
  )
}

Days.propTypes = {
  start: PropTypes.number,
}

export default Days
