// Utils and Service
import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Creates a line item using the specified `payload`.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const createLineItem = async (
  dealerId,
  repairOrderId,
  payload,
  setLoading,
  setError,
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/`,
      payload,
    )

    setLoading(false)
    setSuccess('Line item created.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Update a line item using the specified `payload`.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} lineItemId
 * @param {string} payload
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const updateLineItem = async (
  dealerId,
  repairOrderId,
  lineItemId,
  payload,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.patch(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/`,
      payload,
    )

    setLoading(false)
    setSuccess('Line item updated.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Delete a line item.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} lineItemId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const deleteLineItem = async (
  dealerId,
  repairOrderId,
  lineItemId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.delete(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/`,
    )
    setLoading(false)
    setSuccess('Line item deleted.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Upload a file to line item.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} lineItemId
 * @param {object} formData
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const createLineItemFile = async (
  dealerId,
  repairOrderId,
  lineItemId,
  formData,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.post(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/files/`,
      formData,
    )
    setLoading(false)
    setSuccess('File uploaded.', response)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Delete a file from a line item.
 * @param {string} dealerId
 * @param {string} repairOrderId
 * @param {string} lineItemId
 * @param {string} lineItemFileId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const deleteLineItemFile = async (
  dealerId,
  repairOrderId,
  lineItemId,
  lineItemFileId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)
  try {
    const response = await axios.delete(
      `/dealers/${dealerId}/repair-orders/${repairOrderId}/line-items/${lineItemId}/files/${lineItemFileId}/`,
    )
    setLoading(false)
    setSuccess('File deleted.')
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
