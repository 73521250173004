import { createContext } from 'react'
import { action, observable } from 'mobx'

// Store
import { BaseStore } from './BaseStore'

const INITIAL_STATE = {
  task: null,
  details: null,
  status: false,
  type: null,
  loading: false,
  lastUpdated: null,
  error: null,
}

const TYPES = {
  task: observable,
  details: observable,
  status: observable,
  type: observable,
  loading: observable,
  error: observable,
  lastUpdated: observable,
}

export class TaskStore extends BaseStore {
  setDetails = action((details) => {
    this.details = details
  })

  setType = action((type) => {
    this.type = type
  })

  setTask = action((task) => {
    if (task === null) this.status = 'processing'
    this.task = task
  })

  setStatus = action((status) => {
    this.status = status
  })

  createNewTask = action((type, task, details) => {
    this.setType(type)
    this.setTask(task)
    this.setDetails(details)
  })
}

export const task = new TaskStore(INITIAL_STATE, TYPES)
export const TaskStoreContext = createContext(task)
