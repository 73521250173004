// Utils and Service

import { getErrorMessage } from '../utils/helpers'
import axios from './instance'

/**
 * Gets the manufacturers for the specified manufacturerId and repair order status.
 * @param {string} url
 * @param {number} page
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const getNotifications = async (
  url,
  page = 1,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
  withLoadingDelay = false,
) => {
  setLoading(true)

  try {
    const response = await axios.get(url)

    setTimeout(() => setLoading(false), withLoadingDelay ? 1000 : 0)
    setSuccess(response, page)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setTimeout(() => setLoading(false), withLoadingDelay ? 1000 : 0)
    return null
  }
}

/**
 * Marks the notification as read.
 * @param {string} notificationId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markNotificationAsRead = async (
  notificationId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/${notificationId}/mark-as-read/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Marks the notification as unread.
 * @param {string} notificationId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markNotificationAsUnread = async (
  notificationId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/${notificationId}/mark-as-unread/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Marks the notification as archived.
 * @param {string} notificationId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markNotificationAsArchived = async (
  notificationId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/${notificationId}/mark-as-archived/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Marks the notification as unarchived.
 * @param {string} notificationId
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markNotificationAsUnarchived = async (
  notificationId,
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/${notificationId}/mark-as-unarchived/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

// All Notifications

/**
 * Marks all notification as read.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markAllNotificationsAsRead = async (
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/mark-all-as-read/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Marks all notifications as unread.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markAllNotificationsAsUnread = async (
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/mark-all-as-unread/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}

/**
 * Marks all notifications as archived.
 * @param {func} setLoading
 * @param {func} setError
 * @param {func} setSuccess
 */
export const markAllNotificationsAsArchived = async (
  setLoading = () => {},
  setError = () => {},
  setSuccess = () => {},
) => {
  setLoading(true)

  try {
    const response = await axios.post(`/notifications/mark-all-as-archived/`)

    setLoading(false)
    setSuccess(true)
    return response
  } catch (err) {
    setError(getErrorMessage(err))
    setLoading(false)
    return null
  }
}
